import React from "react";
//import GetStartForm from "./getStartForm"

const BenifitsOfOutsourcing = (props) => {
    return (
       <section className="how-can-help benefits">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 mx-auto">
                            <h1 className="heading">Benefits of outsourcing<br /> {props?.title}</h1>
                            <div className="row">
                                <div className="col-lg-6 col-container">
                                    <h5><div className="greenbox"><img src="assets/images/Obtain high-quality leads.png"></img></div><span>Obtain high-quality leads</span></h5>
                                    
                                </div>
                                <div className="col-lg-6 col-container">
                                    <h5><div className="greenbox"><img src="assets/images/Get market insights 1.png"></img></div><span>Book more appointments</span></h5>
                                   
                                </div>
                                <div className="col-lg-6 col-container">
                                    <h5><div className="greenbox"><img src="assets/images/report 2.png"></img></div><span>Optimize time, effort, and resources</span></h5>
                                   
                                </div>
                                <div className="col-lg-6 col-container">
                                    <h5><div className="greenbox"><img src="assets/images/1 8.png"></img></div><span>Boost conversions</span></h5>
                                   
                                </div>
                                <div className="col-lg-6 col-container">
                                    <h5><div className="greenbox"><img src="assets/images/Strengthen your sales team 1.png"></img></div><span>Boost sales team performance</span></h5>
                                    
                                </div>
                                <div className="col-lg-6 col-container">
                                    <h5><div className="greenbox"><img src="assets/images/Stand out from the tight competition 1.png"></img></div><span>Surpass the competition</span></h5>
                                   </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )

}
export default BenifitsOfOutsourcing;