
const ClientsWords = (props) => {

    return (
        <section className={"client-word " + props.className}>
            <div className="container">
                <h2 className="heading">Client <span>Testimonials</span></h2>
                <div id="customers-testimonials" class="owl-carousel">
                <div className="item">
                        <div className="card">
                            <div className="card-body">
                                <img className="img-fluid" src="assets/images/rating.svg" alt="rating" />
                                <h5>Sue Robertson - Suntree Internal Medicine</h5>
                                <p>My Staffwiz transcriptionists are the BEST! Very knowledgeable in medical terminology and dependable.  Whenever I'm in need of another transcriptionist I wouldn't hesitate to take on another Staffwiz MT. </p>
                            </div>
                        </div>
                    </div>
            <div className="item">
                        <div className="card">
                            <div className="card-body">
                                <img className="img-fluid" src="assets/images/rating.svg" alt="rating" />
                                <h5>Julie Leach - Grace Accounting, LLC</h5>
                                <p>StaffWiz (specifically Jerick O.) has been instrumental into the growth of our organization with the candidates that he has presented (and onboarded) for us. I personally was skeptical of hiring individuals that were going to be fully remote (and also living within another country), but the experience that Jerick has provided our organization makes me believe in the greater global economy and I couldn't be happier with the result. This is truly one of the best decisions that we have ever made for our organization. I would highly recommend StaffWiz to anyone that is looking for quality individuals to grow their organization.</p>
                            </div>
                        </div>
                    </div>
                    <div className="item">
                        <div className="card">
                            <div className="card-body">
                                <img className="img-fluid" src="assets/images/rating.svg" alt="rating" />
                                <h5>Amelie Peterson - TMC inc</h5>
                                <p>hesitantly hired a VA ( virtual assistant) for my E commerce business through Staffwiz in the Philippines, I was skeptical that an offshore staff would not be comparable to an American employee, but I have to say my VA proved me wrong she is simply phenomenal.</p>
                            </div>
                        </div>
                    </div>

                    <div class="item">
                        <div class="card">
                            <div class="card-body">
                                <img class="img-fluid" src="assets/images/rating.svg" alt="rating" />
                                <h5>D Bhatta - Bhatta Law llc</h5>
                                <p>My Law firm gets a lot of inbound calls concerning immigration matters, we needed a couple of customer support reps to assist us with this. staffwiz found a great team for us we are very happy with them.</p>
                            </div>
                        </div>
                    </div>
                    <div class="item">
                        <div class="card">
                            <div class="card-body">
                                <img class="img-fluid" src="assets/images/rating.svg" alt="rating" />
                                <h5>Pravez Gondal - Gondal LLC</h5>
                                <p>Staffwiz helped me find medical billing staff for my Medical staffing business at a great rate, they really match you with a team that has the right skillset youneed for your business. I hope to hire more staff in the next 3 months.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <img src="assets/images/Ellipse 91.png" alt="" className="service-img" />
            </div>
            
        </section>
    );

}

export default ClientsWords;