import React from "react";
import "./index.css";
import { Collapse } from "react-collapse";
import { Helmet } from "react-helmet";

export default class FAQ extends React.Component {
  state = {
    Recruitment_Open1: false,
    Recruitment_Open2: false,
    Princing_Open1: false,
    Princing_Open2: false,
    Princing_Open3: false,
    Princing_Open4: false,
    Princing_Open5: false,
    Princing_Open6: false,
    Princing_Open7: false,
    Billing_Open1: false,
    Billing_Open2: false,
    Billing_Open3: false,
    Billing_Open4: false,
    Billing_Open5: false,
    Billing_Open6: false,
    Staff_Open1: false,
    Staff_Open2: false,
    Staff_Open3: false,
    Staff_Open4: false,
    Staff_Open5: false,
    Staff_Open6: false,
    Staff_Open7: false,
    Staff_Open8: false,
    Staff_Open9: false,
    Staff_Open10: false,
    Contracts_Open1: false,
    Contracts_Open2: false,
    Contracts_Open3: false,
    Contracts_Open4: false,
    Contracts_Open5: false,
    Contracts_Open6: false,
    Benefits_Open1: false,
    Benefits_Open2: false,
    Benefits_Open3: false,
    others_Open1: false,
  };

  handle_Recruitment_Open1 = () => {
    this.setState({
      Recruitment_Open1: !this.state.Recruitment_Open1,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Recruitment_Open2 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: !this.state.Recruitment_Open2,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };
  handle_Princing_Open1 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: !this.state.Princing_Open1,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };
  handle_Princing_Open2 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: !this.state.Princing_Open2,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Princing_Open3 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: !this.state.Princing_Open3,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Princing_Open4 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: !this.state.Princing_Open4,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Princing_Open5 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: !this.state.Princing_Open5,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Princing_Open6 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: !this.state.Princing_Open6,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Princing_Open7 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: !this.state.Princing_Open7,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };
  handle_Billing_Open1 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: !this.state.Billing_Open1,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };
  handle_Billing_Open2 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: !this.state.Billing_Open2,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Billing_Open3 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: !this.state.Billing_Open3,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Billing_Open4 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: !this.state.Billing_Open4,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Billing_Open5 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: !this.state.Billing_Open5,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Billing_Open6 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: !this.state.Billing_Open6,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Staff_Open1 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: !this.state.Staff_Open1,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };
  handle_Staff_Open2 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: !this.state.Staff_Open2,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };
  handle_Staff_Open3 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: !this.state.Staff_Open3,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };
  handle_Staff_Open4 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: !this.state.Staff_Open4,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };
  handle_Staff_Open5 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: !this.state.Staff_Open5,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };
  handle_Staff_Open6 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: !this.state.Staff_Open6,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };
  handle_Staff_Open7 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: !this.state.Staff_Open7,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };
  handle_Staff_Open8 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: !this.state.Staff_Open8,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };
  handle_Staff_Open9 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: !this.state.Staff_Open9,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };
  handle_Staff_Open10 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: !this.state.Staff_Open10,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };
  handle_Contracts_Open1 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: !this.state.Contracts_Open1,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Contracts_Open2 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: !this.state.Contracts_Open2,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Contracts_Open3 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: !this.state.Contracts_Open3,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Contracts_Open4 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: !this.state.Contracts_Open4,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Contracts_Open5 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: !this.state.Contracts_Open5,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Contracts_Open6 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: !this.state.Contracts_Open6,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Benefits_Open1 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: !this.state.Benefits_Open1,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Benefits_Open2 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: !this.state.Benefits_Open2,
      Benefits_Open3: false,
      others_Open1: false,
    });
  };

  handle_Benefits_Open3 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: !this.state.Benefits_Open3,
      others_Open1: false,
    });
  };

  handle_others_Open1 = () => {
    this.setState({
      Recruitment_Open1: false,
      Recruitment_Open2: false,
      Princing_Open1: false,
      Princing_Open2: false,
      Princing_Open3: false,
      Princing_Open4: false,
      Princing_Open5: false,
      Princing_Open6: false,
      Princing_Open7: false,
      Billing_Open1: false,
      Billing_Open2: false,
      Billing_Open3: false,
      Billing_Open4: false,
      Billing_Open5: false,
      Billing_Open6: false,
      Staff_Open1: false,
      Staff_Open2: false,
      Staff_Open3: false,
      Staff_Open4: false,
      Staff_Open5: false,
      Staff_Open6: false,
      Staff_Open7: false,
      Staff_Open8: false,
      Staff_Open9: false,
      Staff_Open10: false,
      Contracts_Open1: false,
      Contracts_Open2: false,
      Contracts_Open3: false,
      Contracts_Open4: false,
      Contracts_Open5: false,
      Contracts_Open6: false,
      Benefits_Open1: false,
      Benefits_Open2: false,
      Benefits_Open3: false,
      others_Open1: !this.state.others_Open1,
    });
  };

  render() {
    return (
      <div>

        <Helmet>
        <title>FAQS | Staffwiz</title>
            <meta name="description" content="Frequenlty Asked Question for Staffwiz company." />
          <script type="application/ld+json">{JSON.stringify({
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [
              {
                "@type": "Question",
                "name": "Are your candidates screened?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes. We check with previous employers to verify the accuracy of the information they provide."
                }
              },
              {
                "@type": "Question",
                "name": "What is your process of recruitment?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "After sending out a call, one of our recruitment consultants will start the process. They will conduct interviews, personality and skills tests (if needed), and background checks. If approved, the candidate will be endorsed to the requesting client, who then conducts the final interview with the recruitment consultant, but makes the final decision themselves."
                }
              },
              {
                "@type": "Question",
                "name": "Do your fees include any phone charges?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We do not include any phone charges on our end. The client is responsible for external phone charges. We encourage clients to use Skype or Zoom to call their workers."
                }
              },
              {
                "@type": "Question",
                "name": "How is overtime handled?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Overtime work is defined as any hours worked in addition to the worker's regular hours, but not counting the 1-hour lunch break during a 9-hour, full-time shift. For part-time workers, overtime work is defined as any hours worked in addition to their 4-hour shift. Overtime work is charged at the normal hourly rate. There is no extra fee above the regular hourly rate when a worker works in addition to the agreed-upon hours or during holidays."
                }
              },
              {
                "@type": "Question",
                "name": "Is there a set-up fee?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "There is no set-up fee for clients."
                }
              },
              {
                "@type": "Question",
                "name": "Does the actual cost reflect the listed price?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The listed prices are provided to give clients a general idea on how much it would cost to hire professionals for certain roles, Our worker's actual rates will vary. There will be no set-up fee or any other additional costs."
                }
              },
              {
                "@type": "Question",
                "name": "Are my holidays paid?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "If working full-time, workers usually get paid days off following either Philippine public holidays or the client's own public holidays. This will be agreed upon before the start of the worker's service provision, subject to the client's discretion."
                }
              },
              {
                "@type": "Question",
                "name": "Will I have to pay sales tax?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Australia-registered businesses will have an additional 10% GST on their billing invoice as required by law."
                }
              },
              {
                "@type": "Question",
                "name": "How much is it going to cost me?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The pay rate is based on the tasks assigned and the skills required for the assigned task. Our pricing page will give an average estimate so you know what range to expect.\nThe monthly total cost of a worker represents the labour costs, plus HR and payroll management. The monthly invoice our clients receive covers all of these costs.\nPlease note that since we work with live exchange rates, billing per month may vary."
                }
              },
              {
                "@type": "Question",
                "name": "What happens to paid hours when the worker hasn't fulfilled them?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Hours unfulfilled by the worker shall be deducted from the following month's invoice."
                }
              },
              {
                "@type": "Question",
                "name": "Do I get a refund when my worker is away?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "As per our no-work no-pay arrangement, clients pay only for hours of proven work. If a worker is not logged in the system for 1 hour (60 minutes) or more, a credit memo equal to that worker's hourly rate will be given to the client for each unaccounted hour, unless the worker was permitted to make up for the lost hours.\nThe credit memo will be added to the client's invoice for the following payment cycle.\nHowever, there is an exception: if a client asks their full-time worker not to work during one of the client's local holidays (not the worker's), the client will need to pay the worker for the time off."
                }
              },
              {
                "@type": "Question",
                "name": "Can I pay my worker in advance?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "<p>Our Terms and Conditions note that you must prepay your worker's work hours a month in advance. Securing a month's pay for your worker:</p><ul><li>Ensures that workers are promptly paid for all the hours they worked for that month.</li><li>Strengthens your long-term business relationship through improved trust.</li></ul>"
                }
              },
              {
                "@type": "Question",
                "name": "Do you offer refunds?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Since time must be paid for in advance, refunds are issued as credit for unused hours. This will be deducted from the amount due in your next invoice. However, for the end of service, it will be credited back to your designated bank account within 3-5 business days after the last working day of the worker."
                }
              },
              {
                "@type": "Question",
                "name": "How am I billed?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "You will receive an invoice at the beginning of each month indicating the amount to be paid for that month. Settlement of the invoice should be within 3 days after receipt. All prepaid hours from the previous month that were unused will be acknowledged and credited to your next month's bill."
                }
              },
              {
                "@type": "Question",
                "name": "What modes of payment are accepted?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": `Clients from various countries can use their own currency to pay Remote workers through various channels. Regardless of how you pay, you will receive an invoice displaying due amounts for the month and credited hours, if there are any. You may refer to the information below for payment channels available to clients per location.
<p>Australia and New Zealand (AUD, NZD)</p>
<ul>
<li>Bank transfer directly into an Australian bank account</li>
<li>Transferwise</li>
<li>Paypal with a transaction fee averaging to about 3.5% billable to clients on succeeding invoice</li>
</ul>
<p>US/Canada (USD, CAD)</p>
<ul>
<li>Bank or wire transfer (Transferwise)</li>
<li>Paypal with a transaction fee averaging to about 3.5% billable to clients on succeeding invoice</li>
</ul>
<p>Europe (USD, EUR, GBP)</p>
<ul>
<li>Bank or wire transfer (Transferwise)</li>
<li>Paypal with a transaction fee averaging to about 3.5% billable to clients on succeeding invoice</li></ul>`
                }
              },
              {
                "@type": "Question",
                "name": "Do you do performance and salary evaluations?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes. Evaluations are made during the 6th month after a worker started working for a client. This is carried out to see if the dedicated worker member is still handling the original role they were hired to do, and to see if there is a case to discuss appraisal-related pay for outstanding performance."
                }
              },
              {
                "@type": "Question",
                "name": "Is my worker eligible for a raise?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "You may give additional compensation to your workers in the form of performance incentives. Your workers shall receive the bonus added to their pay but will be clearly communicated to them as a bonus and not a part of their basic pay."
                }
              },
              {
                "@type": "Question",
                "name": "How do my Philippine-based employees get their money?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Their salaries and wages are sent directly tho payroll bank accounts, through the bank chosen by Staffwiz Inc. Employees are paid on time everytime."
                }
              },
              {
                "@type": "Question",
                "name": "What to do when I'm dissatisfied with the provided worker?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "You can ask us to find you a replacement worker for the same role free of charge."
                }
              },
              {
                "@type": "Question",
                "name": "Is my worker reasonably compensated?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes, more than enough. The compensation our workers receive are above the industry average in the Philippines."
                }
              },
              {
                "@type": "Question",
                "name": "Does the Philippines suffer from a bad internet connection? What if my worker fails to connect to the internet?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We always recommend to our workers to have a back-up internet connection they can rely on, or at least have a second possible work location for business continuity purposes. Your worker will be instructed to notify your Account Manager as soon as an internet connection failure occurs so you can be notified."
                }
              },
              {
                "@type": "Question",
                "name": "On what time can my worker work?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Your worker can work on any schedule, so long as you both agree on it."
                }
              },
              {
                "@type": "Question",
                "name": "Can my worker take calls?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Yes. Our clients use a variety of softphone services depending on the types of calls that will be conducted. If you need a simple and easy-to-use service, there is always Skype or Zoom. All VoiP or softphone services will be handled and maintained directly by clients, and are not included in the rate per hour."
                }
              },
              {
                "@type": "Question",
                "name": "What type of tasks can my worker do?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Any task you need done, so long as it fits in the requirements you listed. It can go from general administrative tasks, to highly technical digital marketing or programming responsibilities. The discovery call helps us determine the type of support you really need and the skills that your ideal worker should possess."
                }
              },
              {
                "@type": "Question",
                "name": "Are the workers trained?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "We conduct onboarding/orientation for new workers, but the actual work and process training will come directly from the client."
                }
              },
              {
                "@type": "Question",
                "name": "Do I have to sign any contract for the people who'll work for my company?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "You must agree to our Terms and Conditions, but you won't be asked to sign a contract that will lock you in our service. Workers sign a contract with us so that they can perform the role they are hired for."
                }
              },
              {
                "@type": "Question",
                "name": "Is the worker your employee or mine?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "The worker will officially be our employee. However this employee would be exclusively working for you."
                }
              },
              {
                "@type": "Question",
                "name": "The worker I hire will have access to my confidential information. Will my business be protected with an NDA?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Every worker who goes on-board signs a contract, which includes a confidentiality clause. You can always furnish us with your Own NDA that we can implement to your worker's processes and procedures upon review and approval of our legal department."
                }
              },
              {
                "@type": "Question",
                "name": "Is there a trial period?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "There is no trial period. However, within the first 3 months, you will be allowed to end the service after providing a 30-day notice."
                }
              },
              {
                "@type": "Question",
                "name": "Is there an option to change full-time hiring to part-time or vice-versa?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "At present, we only offer full-time workers, working 40hours per week."
                }
              },
              {
                "@type": "Question",
                "name": "Can I purchase a specific amount of hours?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "At present, we only offer full-time workers, working 40hours per week."
                }
              },
              {
                "@type": "Question",
                "name": "Will I be paying for health insurance or benefits?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Health insurance and other forms of benefits are additional incentives that you can give your workers after a year of working with you and your business. This is not mandatory but can be an excellent incentive option to acknowledge your worker's loyalty, hard work and excellent performance at work."
                }
              },
              {
                "@type": "Question",
                "name": "Is my worker eligible for paid leave credits (sick/vacation/emergency)?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "It is the client's prerogative if they want to give their worker leave credits. Providing this benefit could be done after a performance appraisal, as an acknowledgement of the worker's outstanding performance."
                }
              },
              {
                "@type": "Question",
                "name": "Will I be paying the 13th month's salary?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "No, it is not mandatory for clients to pay the 13th month to their workers. However, you are free to award bonus/es to your workers any time you please as a sign of appreciation for the excellent work they have done throughout the year, or for any other reason you deem fit."
                }
              }
            ]
          }
          )}</script>
        </Helmet>





        <div className="faq-contaner">
          <h1 className=" text-center mt-5">FAQs</h1>
          <h2 className="mt-4">RECRUITMENT</h2>
          <div className="faq-heading mt-2">
            <p>Are your candidates screened?</p>
    
            <a onClick={this.handle_Recruitment_Open1}>
              {this.state.Recruitment_Open1 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Recruitment_Open1}>
            <div className="faq-data">
              <p>
                Yes. We check with previous employers to verify the accuracy of
                the information they provide.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading ">
            <p className="d-inline">What is your process of recruitment?</p>
            <a onClick={this.handle_Recruitment_Open2}>
              {this.state.Recruitment_Open2 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Recruitment_Open2}>
            <div className="faq-data">
              <p>
                After sending out a call, one of our recruitment consultants
                will start the process. They will conduct interviews,
                personality and skills tests (if needed), and background checks.
                If approved, the candidate will be endorsed to the requesting
                client, who then conducts the final interview with the
                recruitment consultant, but makes the final decision themselves.
              </p>
            </div>
          </Collapse>
          <h2 className="mt-4">PRICING</h2>
          <div className="faq-heading mt-2">
            <p>Do your fees include any phone charges?</p>
            <a onClick={this.handle_Princing_Open1}>
              {this.state.Princing_Open1 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Princing_Open1}>
            <div className="faq-data">
              <p>
                We do not include any phone charges on our end. The client is
                responsible for external phone charges. We encourage clients to
                use Skype or Zoom to call their workers.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>How is overtime handled?</p>
            <a onClick={this.handle_Princing_Open2}>
              {this.state.Princing_Open2 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Princing_Open2}>
            <div className="faq-data">
              <p>
                Overtime work is defined as any hours worked in addition to the
                worker's regular hours, but not counting the 1-hour lunch break
                during a 9-hour, full-time shift. For part-time workers,
                overtime work is defined as any hours worked in addition to
                their 4-hour shift. Overtime work is charged at the normal
                hourly rate. There is no extra fee above the regular hourly rate
                when a worker works in addition to the agreed-upon hours or
                during holidays.
              </p>
            </div>
          </Collapse>

          <div className="faq-heading">
            <p>Is there a set-up fee?</p>
            <a onClick={this.handle_Princing_Open3}>
              {this.state.Princing_Open3 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Princing_Open3}>
            <div className="faq-data">
              <p>There is no set-up fee for clients</p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>Does the actual cost reflect the listed price?</p>
            <a onClick={this.handle_Princing_Open4}>
              {this.state.Princing_Open4 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Princing_Open4}>
            <div className="faq-data">
              <p>
                The listed prices are provided to give clients a general idea on
                how much it would cost to hire professionals for certain roles,
                Our worker's actual rates will vary. There will be no set-up fee
                or any other additional costs.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>Are my holidays paid?</p>
            <a onClick={this.handle_Princing_Open5}>
              {this.state.Princing_Open5 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Princing_Open5}>
            <div className="faq-data">
              <p>
                If working full-time, workers usually get paid days off
                following either Philippine public holidays or the client's own
                public holidays. This will be agreed upon before the start of
                the worker's service provision, subject to the client's
                discretion.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>Will I have to pay sales tax?</p>
            <a onClick={this.handle_Princing_Open6}>
              {this.state.Princing_Open6 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Princing_Open6}>
            <div className="faq-data">
              <p>
                Australia-registered businesses will have an additional 10% GST
                on their billing invoice as required by law.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>How much is it going to cost me?</p>
            <a onClick={this.handle_Princing_Open7}>
              {this.state.Princing_Open7 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Princing_Open7}>
            <div className="faq-data">
              <p>
                The pay rate is based on the tasks assigned and the skills
                required for the assigned task. Our pricing page will give an
                average estimate so you know what range to expect.
              </p>
              <p>
                The monthly total cost of a worker represents the labour costs,
                plus HR and payroll management. The monthly invoice our clients
                receive covers all of these costs.
              </p>
              <p>
                Please note that since we work with live exchange rates, billing
                per month may vary.
              </p>
            </div>
          </Collapse>
          <h2 className="mt-4">BILLING</h2>
          <div className="faq-heading mt-2">
            <p>
              What happens to paid hours when the worker hasn't fulfilled them?
            </p>
            <a onClick={this.handle_Billing_Open1}>
              {this.state.Billing_Open1 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Billing_Open1}>
            <div className="faq-data">
              <p>
                Hours unfulfilled by the worker shall be deducted from the
                following month's invoice.
              </p>
            </div>
          </Collapse>

          <div className="faq-heading">
            <p>Do I get a refund when my worker is away?</p>
            <a onClick={this.handle_Billing_Open2}>
              {this.state.Billing_Open2 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Billing_Open2}>
            <div className="faq-data">
              <p>
                As per our no-work no-pay arrangement, clients pay only for
                hours of proven work. If a worker is not logged in the system
                for 1 hour (60 minutes) or more, a credit memo equal to that
                worker's hourly rate will be given to the client for each
                unaccounted hour, unless the worker was permitted to make up for
                the lost hours.
              </p>
              <p>
                The credit memo will be added to the client's invoice for the
                following payment cycle.
              </p>
              <p>
                However, there is an exception: if a client asks their full-time
                worker not to work during one of the client's local holidays
                (not the worker's), the client will need to pay the worker for
                the time off.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>Can I pay my worker in advance?</p>
            <a onClick={this.handle_Billing_Open3}>
              {this.state.Billing_Open3 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Billing_Open3}>
            <div className="faq-data">
              <p>
                Our Terms and Conditions note that you must prepay your worker's
                work hours a month in advance. Securing a month's pay for your
                worker:
              </p>
              <ul>
                <li>
                  Ensures that workers are promptly paid for all the hours they
                  worked for that month
                </li>
                <li>
                  Strengthens your long-term business relationship through
                  improved trust.
                </li>
              </ul>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>Do you offer refunds?</p>
            <a onClick={this.handle_Billing_Open4}>
              {this.state.Billing_Open4 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Billing_Open4}>
            <div className="faq-data">
              <p>
                Since time must be paid for in advance, refunds are issued as
                credit for unused hours. This will be deducted from the amount
                due in your next invoice. However, for the end of service, it
                will be credited back to your designated bank account within 3-5
                business days after the last working day of the worker.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>How am I billed?</p>
            <a onClick={this.handle_Billing_Open5}>
              {this.state.Billing_Open5 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Billing_Open5}>
            <div className="faq-data">
              <p>
                You will receive an invoice at the beginning of each month
                indicating the amount to be paid for that month. Settlement of
                the invoice should be within 3 days after receipt. All prepaid
                hours from the previous month that were unused will be
                acknowledged and credited to your next month's bill.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>What modes of payment are accepted?</p>
            <a onClick={this.handle_Billing_Open6}>
              {this.state.Billing_Open6 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Billing_Open6}>
            <div className="faq-data">
              <p>
                Clients from various countries can use their own currency to pay
                Remote workers through various channels. Regardless of how you
                pay, you will receive an invoice displaying due amounts for the
                month and credited hours, if there are any.
              </p>
              <p>
                You may refer to the information below for payment channels
                available to clients per location.
              </p>
              <p>
                <b>Australia and New Zealand (AUD, NZD)</b>
              </p>
              <ul>
                <li>Bank transfer directly into an Australian bank account</li>
                <li>Transferwise</li>
                <li>
                  Paypal with a transaction fee averaging to about 3.5% billable
                  to clients on succeeding invoice
                </li>
              </ul>

              <p>
                <b>US/Canada (USD, CAD)</b>
              </p>
              <ul>
                <li>Bank or wire transfer (Transferwise)</li>
                <li>
                  Paypal with a transaction fee averaging to about 3.5% billable
                  to clients on succeeding invoice
                </li>
              </ul>
              <p>
                <b>Europe (USD, EUR, GBP)</b>
              </p>
              <ul>
                <li>Bank or wire transfer (Transferwise)</li>
                <li>
                  Paypal with a transaction fee averaging to about 3.5% billable
                  to clients on succeeding invoice
                </li>
              </ul>
            </div>
          </Collapse>
          <h2 className="mt-4">Staff</h2>
          <div className="faq-heading mt-2">
            <p>Do you do performance and salary evaluations?</p>
            <a onClick={this.handle_Staff_Open1}>
              {this.state.Staff_Open1 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Staff_Open1}>
            <div className="faq-data">
              <p>
                Yes. Evaluations are made during the 6th month after a worker
                started working for a client. This is carried out to see if the
                dedicated worker member is still handling the original role they
                were hired to do, and to see if there is a case to discuss
                appraisal-related pay for outstanding performance.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>Is my worker eligible for a raise?</p>
            <a onClick={this.handle_Staff_Open2}>
              {this.state.Staff_Open2 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Staff_Open2}>
            <div className="faq-data">
              <p>
                You may give additional compensation to your workers in the form
                of performance incentives. Your workers shall receive the bonus
                added to their pay but will be clearly communicated to them as a
                bonus and not a part of their basic pay.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>How do my Philippine-based employees get their money?</p>
            <a onClick={this.handle_Staff_Open3}>
              {this.state.Staff_Open3 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Staff_Open3}>
            <div className="faq-data">
              <p>
                Their salaries and wages are sent directly tho payroll bank
                accounts, through the bank chosen by Staffwiz Inc. Employees are
                paid on time everytime.
              </p>
            </div>
          </Collapse>

          <div className="faq-heading">
            <p>What to do when I'm dissatisfied with the provided worker?</p>
            <a onClick={this.handle_Staff_Open4}>
              {this.state.Staff_Open4 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Staff_Open4}>
            <div className="faq-data">
              <p>
                You can ask us to find you a replacement worker for the same
                role free of charge.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>Is my worker reasonably compensated?</p>
            <a onClick={this.handle_Staff_Open5}>
              {this.state.Staff_Open5 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Staff_Open5}>
            <div className="faq-data">
              <p>
                Yes, more than enough. The compensation our workers receive are
                above the industry average in the Philippines.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>
              Does the Philippines suffer from a bad internet connection? What
              if my worker fails to connect to the internet?
            </p>
            <a onClick={this.handle_Staff_Open6}>
              {this.state.Staff_Open6 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Staff_Open6}>
            <div className="faq-data">
              <p>
                We always recommend to our workers to have a back-up internet
                connection they can rely on, or at least have a second possible
                work location for business continuity purposes. Your worker will
                be instructed to notify your Account Manager as soon as an
                internet connection failure occurs so you can be notified.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>On what time can my worker work?</p>
            <a onClick={this.handle_Staff_Open7}>
              {this.state.Staff_Open7 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Staff_Open7}>
            <div className="faq-data">
              <p>
                Your worker can work on any schedule, so long as you both agree
                on it.{" "}
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>Can my worker take calls?</p>
            <a onClick={this.handle_Staff_Open8}>
              {this.state.Staff_Open8 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Staff_Open8}>
            <div className="faq-data">
              <p>
                Yes. Our clients use a variety of softphone services depending
                on the types of calls that will be conducted. If you need a
                simple and easy-to-use service, there is always Skype or Zoom.
                All VoiP or softphone services will be handled and maintained
                directly by clients, and are not included in the rate per hour.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>What type of tasks can my worker do?</p>
            <a onClick={this.handle_Staff_Open9}>
              {this.state.Staff_Open9 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Staff_Open9}>
            <div className="faq-data">
              <p>
                Any task you need done, so long as it fits in the requirements
                you listed. It can go from general administrative tasks, to
                highly technical digital marketing or programming
                responsibilities. The discovery call helps us determine the type
                of support you really need and the skills that your ideal worker
                should possess.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>Are the workers trained?</p>
            <a onClick={this.handle_Staff_Open10}>
              {this.state.Staff_Open10 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Staff_Open10}>
            <div className="faq-data">
              <p>
                We conduct onboarding/orientation for new workers, but the
                actual work and process training will come directly from the
                client.
              </p>
            </div>
          </Collapse>
          <h2 className="mt-4">CONTRACTS</h2>
          <div className="faq-heading mt-2">
            <p>
              Do I have to sign any contract for the people who'll work for my
              company?
            </p>
            <a onClick={this.handle_Contracts_Open1}>
              {this.state.Contracts_Open1 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Contracts_Open1}>
            <div className="faq-data">
              <p>
                You must agree to our Terms and Conditions, but you won't be
                asked to sign a contract that will lock you in our service.
                Workers sign a contract with us so that they can perform the
                role they are hired for.{" "}
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>Is the worker your employee or mine?</p>
            <a onClick={this.handle_Contracts_Open2}>
              {this.state.Contracts_Open2 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Contracts_Open2}>
            <div className="faq-data">
              <p>
                The worker will officially be our employee. However this
                employee would be exclusively working for you.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>
              The worker I hire will have access to my confidential information.
              Will my business be protected with an NDA?
            </p>
            <a onClick={this.handle_Contracts_Open3}>
              {this.state.Contracts_Open3 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Contracts_Open3}>
            <div className="faq-data">
              <p>
                Every worker who goes on-board signs a contract, which includes
                a confidentiality clause. You can always furnish us with your
                Own NDA that we can implement to your worker's processes and
                procedures upon review and approval of our legal department.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>Is there a trial period?</p>
            <a onClick={this.handle_Contracts_Open4}>
              {this.state.Contracts_Open4 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Contracts_Open4}>
            <div className="faq-data">
              <p>
                There is no trial period. However, within the first 3 months,
                you will be allowed to end the service after providing a 30-day
                notice.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>
              Is there an option to change full-time hiring to part-time or
              vice-versa?
            </p>
            <a onClick={this.handle_Contracts_Open5}>
              {this.state.Contracts_Open5 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Contracts_Open5}>
            <div className="faq-data">
              <p>
                At present, we only offer full-time workers, working 40hours per
                week
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>Can I purchase a specific amount of hours?</p>
            <a onClick={this.handle_Contracts_Open6}>
              {this.state.Contracts_Open6 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Contracts_Open6}>
            <div className="faq-data">
              <p>
                At present, we only offer full-time workers, working 40hours per
                week
              </p>
            </div>
          </Collapse>
          <h2 className="mt-4">BENEFITS</h2>
          <div className="faq-heading mt-2">
            <p>Will I be paying for health insurance or benefits?</p>
            <a onClick={this.handle_Benefits_Open1}>
              {this.state.Benefits_Open1 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Benefits_Open1}>
            <div className="faq-data">
              <p>
                Health insurance and other forms of benefits are additional
                incentives that you can give your workers after a year of
                working with you and your business. This is not mandatory but
                can be an excellent incentive option to acknowledge your
                worker's loyalty, hard work and excellent performance at work.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>
              Is my worker eligible for paid leave credits
              (sick/vacation/emergency)?
            </p>
            <a onClick={this.handle_Benefits_Open2}>
              {this.state.Benefits_Open2 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Benefits_Open2}>
            <div className="faq-data">
              <p>
                It is the client's prerogative if they want to give their worker
                leave credits. Providing this benefit could be done after a
                performance appraisal, as an acknowledgement of the worker's
                outstanding performance.
              </p>
            </div>
          </Collapse>
          <div className="faq-heading">
            <p>Will I be paying the 13th month's salary?</p>
            <a onClick={this.handle_Benefits_Open3}>
              {this.state.Benefits_Open3 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.Benefits_Open3}>
            <div className="faq-data">
              <p>
                No, it is not mandatory for clients to pay the 13th month to
                their workers. However, you are free to award bonus/es to your
                workers any time you please as a sign of appreciation for the
                excellent work they have done throughout the year, or for any
                other reason you deem fit.
              </p>
            </div>
          </Collapse>
          <h2 className="mt-4">OTHERS</h2>
          <div className="faq-heading mt-2">
            <p>I have more questions!</p>
            <a onClick={this.handle_others_Open1}>
              {this.state.others_Open1 ? "-" : "+"}
            </a>
          </div>
          <Collapse isOpened={this.state.others_Open1}>
            <div className="faq-data">
              <p>
                Please reach out to us if you have any questions, we will be
                happy to answer them.
              </p>
            </div>
          </Collapse>
        </div>
        <div className="mt-5">

        </div>
      </div >
    );
  }
}