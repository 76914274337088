import React from "react";
import GetStartForm from "./getStartForm"

const HireTalent = () => {
    return (
        <section className="hire-talent">
            <div className="container">
                <div className="row ">
                    <div className="col-lg-7">
                        <img
                            src="assets/images/hire-talent.svg"
                            className="img-fluid w-100"
                        ></img>
                        <div className="">
                            <img src="assets/images/hire (2).svg"></img>
                        </div>
                        <div className="mt-3 ml-1">
                            <img src="assets/images/hire (1).png"></img>
                        </div>
                    </div>
                    <div className="col-lg-5">
                        <GetStartForm
                            subheading="Set up your remote team offshore and save up to 70%"
                        />
                    </div>
                </div>
            </div>
        </section>
    )

}
export default HireTalent;