import React from "react";
import "./index.css";
import "../Home/index.css";
import ClientsWord from "../Home/ClientWord";
import StrategicPartners from "../Home/StrategicPartners";
import { PopupButton } from "react-calendly";
import { APP_CONFIG } from "../../config/config";

const ThankYou = () => {

    return (
        <>
        <div className="schedule-call-container">
            <div className="container">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                        <div className="team-popup-cotent">

                            <div className="bill-heading">
                                <div className="position-relative margin-t20px text-center">
                                    <h1 className="pt-5 font-weight-bold">
                                        THANK YOU FOR BOOKING A CALL
                                    </h1>
                                </div>
                            </div>
                                
                        </div>
                    </div>
            </div>
            </div>

<div>

<img src="assets/images/thankyou-banner.png" class="img-fluid thnk-img" alt="Thankyou" />
<div className="gray-color"></div>
</div>

        </div>

            <ClientsWord/>
            <StrategicPartners className="my-5" />
        </>
    )
}

export default ThankYou;