import React from "react";
import { Link } from "react-router-dom";

function PageNotFound() {
    return (
        <>
            <div className="container my-5">
                <h1 className="text-center fw-bold" style={{fontSize:'14rem', color:'#9FCD4E', lineHeight:'12rem'}}>404</h1>
                <h5 className="text-center fw-bold mb-4">The page you are looking for doesn't exist.</h5>
                <div className="text-center mb-5">
                    <Link to="/" className="team-btn">Back To Homepage</Link>
                </div>
            </div>
        </>
    )
}
export default PageNotFound;