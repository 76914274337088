import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/readyToScale"
import ClientsWords from "../../../component/ClientWord";
import StrategicPartners from "../../../component/StrategicPartners";
import {Helmet} from "react-helmet";

const Accounting = () => {
    return (
        <>
        <Helmet>
            <title>Offshore Accounting| Outsourced Bookkeeping | Outsourcing Accounting Services In USA - Staffwiz</title>
            <meta name="description" content="We offer outsourced accounting services and finance solutions on a fixed-fee basis to start-ups and other businesses. Our best outsource accounting services are designed to help you focus on growing your business, allowing you to avoid getting bogged down in financial tasks." />
        </Helmet>
            <section className="home-banner lndg-banner">
                <div className="container">
                    <h1>Hire your world class Accounting Team </h1>
                    <h3 className="my-4">Solutions for startups & small-medium businesses</h3>
                    <div className="row align-items-end">

                        <div className="col-lg-4 right-info">


                            <div className="row px-lg-3 main-catagiory">
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Save 70% on staffing costs</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Hire in weeks not months</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Boost productivity</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Quality & revenue guaranteed </b></h5>
                                </div>
                            </div>
                            <div className="home-rating-banner mt-4">
                                <img src="assets/images/SW Website asset13.png" alt="review stars"></img>
                                <img src="assets/images/HIPAA-COMPLIANT 1.png" alt="review stars"></img>
                                <img src="assets/images/SW Website asset15.png" alt="review stars"></img>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img
                                src="assets/images/home-banner1.png"
                                className="img-fluid" alt="instruction"
                            ></img>
                        </div>
                        <div className="col-lg-4">
                            <GetLandingForm heading={"HIRE NOW"} />
                        </div>
                    </div>
                </div>
            </section>
            {/* details and services */}
            <section className="who-are">
                <div className="container">
                    <h2 className="heading text-center w-100">Hire Accounting experts starting from $7/hr*</h2>
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <p className="lndng-auto">Need a Accounting & Bookkeeping expert … Or Two? Or Maybe a Whole Team?</p>
                            <p className="lndng-auto">Let us show you how outsourcing accounting services can efficiently fulfill your employment needs at a fraction of the cost.</p>
                            <p className="lndng-auto">Staffwiz provides skilled outsourced accounting professionals to handle financial analysis, bookkeeping, and related tasks effortlessly.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-8 landing-hire-bar">
                            <div className="bar1">No contracts</div>
                            <div className="bar2">No obligations</div>
                            <div className="bar3">No hidden fees</div>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Payroll processing</h4>
                            <p>
                                Many outsourced accounting services offer payroll processing, including tasks like calculating employee wages, processing payroll taxes, preparing and distributing paychecks, and generating payroll reports.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Financial analysis</h4>
                            <p>
                                Outsourced accounting services can handle bookkeeping tasks and also offer financial analysis, reporting, and recommendations for improved financial management and decision-making.
                            </p>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Accounts receivable</h4>
                            <p>
                                Accounts receivable involves generating and sending invoices to customers, tracking payments, and following up on overdue payments.</p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Bookkeeping</h4>
                            <p>
                                By outsourcing bookkeeping, companies can leverage the expertise of professional accountants and gain access to efficient financial management services.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
<p className="container">Unlock financial excellence with our outsourced CFO services, delivering strategic insights and expert guidance. Elevate your business efficiency with top-tier accounting outsourcing solutions tailored to your needs.</p>
            <BenifitsOfOutsourcing title={"Accounting and Bookkeeping"} />

            <HiringIsEasy />
<p className="container">Enhance your financial strategy with StaffWiz's offshore accounting expertise. From outsourced bookkeeping to comprehensive accounting services, we optimize your operations. Trust in our proven proficiency in outsourced bookkeeping services to elevate your financial management.</p>
            <ClientsWords />
            <StrategicPartners />

            {/* <HireTalent /> */}
            <ReadyToScale />


        </>

    );
};
export default Accounting;
