import React from "react";
import "./index.css";
import GetStartForm from "../../component/getStartForm"
import HireTalent from "../../component/hireTalent"
import { PopupButton } from "react-calendly";
import { APP_CONFIG } from "../../config/config";
import {Helmet} from "react-helmet";

const GetStarted = () => {
    return (
        <>
        <Helmet>
            <title>Global Outsourcing & Offshoring in USA - StaffWiz</title>
            <meta name="description" content="With a track record since 2000, StaffWiz delivers technology-driven offshore outsourcing solutions to global companies. Ready to scale your business? Contact us now and unlock your growth potential!" />
        </Helmet>
            <section className="home-banner innerHome-banner">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-10 mx-auto">
                            <div className="row">
                                <div className="col-lg-5">

                                    <GetStartForm />

                                    <div className="row px-lg-3 main-catagiory">
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Sales</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Human Resources</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Support</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Marketing</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Accounting</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Operation</b></h5>
                                        </div>
                                    </div>

                                </div>
                                <div className="col-lg-7 right-info">
                                    <h1>
                                        Require one or multiple virtual assistants? Perhaps a team?
                                    </h1>
                                    <img
                                        className="img-fluid "
                                        src="assets/images/SW Industries 1.png"
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="how-work-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <h1 className="heading heading-border">How it works</h1>
                            <p className="section-disc">Whether you’re looking to set up a small group (1-10) for your business or a large team (11-100+) for your contact center, we specialize in global outsourcing, outsourcing, and offshoring. We help guide you and assist in hiring, managing, and setting up your business process. We’re with you before, during, and after you take off, ensuring a seamless transition to optimized operations and enhanced growth.</p>
                            <div className="row">
                                <div className="col-lg-6 col-container">
                                    <div className="work-card card">
                                        <img className="img-fluid w-100" src="assets/images/why-us2 (2).png"></img>
                                        <div className="card-body">
                                            <h5>You select your team</h5>
                                            <h6 className="built-point">Tap and assemble a team utilizing a talent pool that has been pre-screened by us.</h6>
                                            <h6 className="built-point">Interview the selected candidates for final approval.</h6>
                                            <h6 className="built-point">Take charge of training and onboarding with our assistance.</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6 col-container">
                                    <div className="work-card card">
                                        <img className="img-fluid w-100" src="assets/images/why-us2 (1).png"></img>
                                        <div className="card-body">
                                            <h5>We select your team</h5>
                                            <h6 className="built-point">We manage team selection for you.</h6>
                                            <h6 className="built-point">We handle the training and onboarding of your new team.</h6>
                                            <h6 className="built-point">Let us handle team management, QA, and more while you sit back and relax.</h6>
                                        </div>
                                    </div>
                                </div>


                            </div>
                    
                        </div>
                    </div>
                </div>
            </section>
    <p className="container">Explore the realm of global outsourcing with StaffWiz! Elevate your business through strategic outsourcing and offshoring. Curious to learn more? Enquire now and discover how we can optimize your operations, cut costs, and drive growth through our expert solutions.</p>
            <section className="works-steps">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-11 mx-auto">
                            <div className="row">
                                <div className="col-lg-4 col-container">
                                    <img className="connecting-line1" src="assets/images/conacting-line (1).png"></img>
                                    <div className="card">
                                        <div className="card-body">
                                            <h1>1</h1>
                                            <div className="p-3">
                                                <img className="img-fluid icon-img" src="assets/images/steps-work (4).png"></img>
                                                <p>Please provide us with a detailed job description, specifying your specific requirements and needs.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-container offset-lg-4 snd-card ">

                                    <div className="card">

                                        <div className="card-body">
                                            <h1>2</h1>
                                            <div className="p-3">
                                                <img className="img-fluid icon-img" src="assets/images/steps-work (3).png"></img>
                                                <p>We selectively hire highly skilled professionals, ensuring the highest level of quality.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4  col-container">
                                    <img className="connecting-line2" src="assets/images/conacting-line (2).png"></img>
                                    <img className="connecting-line1" src="assets/images/conacting-line (1).png"></img>
                                    <div className="card">
                                        <div className="card-body">
                                            <h1>3</h1>
                                            <div className="p-3">
                                                <img className="img-fluid icon-img" src="assets/images/steps-work (2).png"></img>
                                                <p>Your dedicated staff reports to you daily, whether they work full time remotely or part time.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-container offset-lg-4 snd-card">
                                    <div className="card">
                                        <div className="card-body">
                                            <h1>4</h1>
                                            <div className="p-3">
                                                <img className="img-fluid icon-img" src="assets/images/steps-work (1).png"></img>
                                                <p>We oversee and manage daily operations while ensuring adherence to high-quality standards.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="scale-business">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-8">
                            <h1 className="heading">Ready to scale your business?</h1>
                            <h4><b>Setting up is easy</b></h4>
                            <h5>Call now</h5>
                            <a href="/schedule-call" className="team-btn mt-4 mb-5">FREE CONSULTATION</a>
                        </div>
                        <div className="col-lg-4">
                            <img className="img-fluid " src="assets/images/scale.png"></img>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};
export default GetStarted;
