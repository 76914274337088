import React from "react";
import "./index.css";
import GetStartForm from "../../component/getStartForm"
import HireTalent from "../../component/hireTalent"
import { PopupButton } from "react-calendly";
import { APP_CONFIG } from "../../config/config";
import {Helmet} from "react-helmet";

const Industries = () => {
    return (
        <>
        <Helmet>
            <title>Startup Consulting Services in USA - StaffWiz</title>
            <meta name="description" content="Our startup consultant services empowers fast-growth businesses and entrepreneurs, guiding them through each step of their journey. Benefit from our expertise and unlock your full potential for success." />
        </Helmet>
            <section className="home-banner innerHome-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="row ">
                                <div className="col-lg-7 right-info">
                                    <h1>
                                        Creating teams for offshore operations across all industries.
                                    </h1>
                                    <img
                                        className="img-fluid mb-4"
                                        src="assets/images/shutterstock_1831713958 1.png"
                                    ></img>
                                </div>
                                <div className="col-lg-5">
                                    <GetStartForm />

                                    <div className="row px-lg-3 main-catagiory">
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Sales</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Human Resources</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Support</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Marketing</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Accounting</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Operation</b></h5>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="industries">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <h1 className="heading">Industries</h1>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/healthcare-service">
                                            <img src="assets/images/industry (1).png"></img>
                                            <p>Healthcare BPO Services</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/ecommerce-service">
                                            <img src="assets/images/Ecommerce service Landing Page.png"></img>
                                            <p>Ecommerce Services</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/fintech-service">
                                            <img src="assets/images/Fintech service Landing Page.png"></img>
                                            <p>Fintech Services</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/insurance-service">
                                            <img src="assets/images/Insurance service Landing Page.png"></img>
                                            <p>Insurance Services</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/real-state-service">
                                            <img src="assets/images/Real State service Landing Page.png"></img>
                                            <p>Real State Services</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/retail-service">
                                            <img src="assets/images/Retail service Landing Page.png"></img>
                                            <p>Retail Services</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/travel-support-service">
                                            <img src="assets/images/Travel Support service Landing Page.png"></img>
                                            <p>Travel Support Services</p>
                                        </a>
                                    </div>
                                </div>
                                {/* <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/travel-services">
                                            <img src="assets/images/industry (3).png"></img>
                                            <p>Travel BPO Services</p>
                                        </a>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="build-team business-need">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="buildteam-inner-sec border-0">
                                <div className="row align-items-center">
                                    <div className="col-lg-7">
                                        <h1 className="sub-heading">Does Your Business Need a Call Center? </h1>
                                        <p>
                                            What is one of the most important things that influence clients’ satisfaction? Of course, it’s customer support. There is nothing that can attract or repel potential or present clients better than customer service.
                                        </p>
                                    </div>
                                    <div className="col-lg-5">
                                        <img className="img-fluid" src="assets/images/SW why us (4) 1.png"></img>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="build-team business-need bg-white">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <h1 className="sub-heading text-center mb-5">Here are some signs your customer support service is not satisfying the client's needs:</h1>
                            <div className="buildteam-inner-sec border-0">

                                <div className="row ">
                                    <div className="col-lg-6">
                                        {/* <h6>Here are some signs your customer support service is not satisfying the client's needs:</h6> */}
                                        <div className="servies-list ">
                                            <ul>
                                                <li className="built-point">

                                                    Your clients usually need to wait for a response via email for an indeterminate time, sometimes even a few days;
                                                </li>
                                                <li className="built-point">

                                                    You don’t offer an option to speak with a live person in real-time to provide immediate support;
                                                </li>
                                                <li className="built-point">

                                                    The client has no opportunity to talk to the company’s representative;                                        </li>

                                                <li className="built-point">
                                                    Your employees can’t answer all the calls/requests in a fast and efficient way.
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="servies-list mt-lf-5 ">
                                            <ul>
                                                <li className="built-point">

                                                    There is no person in your company who can understand the client and speak the same non-technical language;
                                                </li>
                                                <li className="built-point">

                                                    It’s impossible for your client to get support on a twenty-four-hour basis or corresponding to the client’s timezone;
                                                </li>
                                                <li className="built-point">

                                                    You have no employees who solely focus on customer support, the workers are constantly distracted;
                                                </li>
                                                <li className="built-point">

                                                    Not well versed with customer support tools.
                                                </li>

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-10 mx-auto">
                                    <div className="text-center mt-5">
                                        <h4 className="sub-heading ">If your business is showing any signs of  the above</h4>
                                       <a href="/schedule-call" className="team-btn mt-4 mb-5">FREE CONSULTATION</a>
                               <p>Staffwiz, is one of the trusted consultant for startup ventures across various industries. Our dedicated team of experts is here to guide you through the intricacies of business initiation, growth, and optimization. With a profound understanding of the unique challenges startups face, we offer tailored solutions that cater to your specific industry requirements. From technology and healthcare to finance and beyond, Staffwiz is your partner in success. As a consultant for startups, we're committed to helping you navigate the competitive landscape, making your journey from inception to success a smooth and strategic one.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="cal-center">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="box-head">
                                <h5>Contact center</h5>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <img className="img-fluid icon-img" src="assets/images/call-center (5).png"></img>
                                </div>
                                <div className="col-3">
                                    <img className="img-fluid icon-img" src="assets/images/call-center (3).png"></img>
                                </div>
                                <div className="col-3">
                                    <img className="img-fluid icon-img" src="assets/images/call-center (1).png"></img>
                                </div>
                                <div className="col-3">
                                    <img className="img-fluid icon-img" src="assets/images/call-center (4).png"></img>
                                </div>
                            </div>
                            <p>is a broader term that refers to a centralized facility where agents are trained to handle customer interactions across multiple channels, including phone calls, email, chat, social media, and more. In other words, a contact center is more of a multi-channel customer service operation that offers a variety of communication options for customers to reach out to a business.</p>
                        </div>
                        <div className="col-lg-6">
                            <div className="box-head">
                                <h5>Call center</h5>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-3">
                                    <img className="img-fluid icon-img" src="assets/images/call-center (2).png"></img>
                                </div>
                                <div className="col-3">
                                    <img className="img-fluid icon-img" src="assets/images/call-center (3).png"></img>
                                </div>

                            </div>
                            <p>Typically refers to a centralized facility where agents are primarily focused on handling inbound and outbound phone calls from customers. These calls could be for a variety of reasons, including customer service inquiries, sales inquiries, technical support, and more.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-client">
                <div className="container">
                    <h1 className="heading">Our client reviews span across various industries.</h1>
                    <div className="row mt-5">
                        <div className="col-lg-4">
                            <img className="img-fluid w-100" src="assets/images/client2.png"></img>
                        </div>
                        <div className="col-lg-8">
                            <img className="img-fluid" src="assets/images/img12.png"></img>
                            <h4>S****e Medical Serivce</h4>
                            <p>I would highly recommend StaffWiz remote medical transcriptionists to anyone in need of this service. Their level of professionalism, expertise, and attention to detail make them a standout choice for any medical service provider. I look forward to working with them again in the future.</p>

                        </div>
                    </div>
                </div>
            </section>

            <HireTalent /> */}
            <section class="client-word">
                <div class="container">
                    <h1 className="heading">Clients Words</h1>
                    <div id="customers-testimonials" class="owl-carousel">
                    <div className="item">
                        <div className="card">
                            <div className="card-body">
                                <img className="img-fluid" src="assets/images/rating.svg" alt="rating" />
                                <h5>Sue Robertson - Suntree Internal Medicine</h5>
                                <p>My Staffwiz transcriptionists are the BEST! Very knowledgeable in medical terminology and dependable.  Whenever I'm in need of another transcriptionist I wouldn't hesitate to take on another Staffwiz MT. </p>
                            </div>
                        </div>
                    </div>
            <div className="item">
                        <div className="card">
                            <div className="card-body">
                                <img className="img-fluid" src="assets/images/rating.svg" alt="rating" />
                                <h5>Julie Leach - Grace Accounting, LLC</h5>
                                <p>StaffWiz (specifically Jerick O.) has been instrumental into the growth of our organization with the candidates that he has presented (and onboarded) for us. I personally was skeptical of hiring individuals that were going to be fully remote (and also living within another country), but the experience that Jerick has provided our organization makes me believe in the greater global economy and I couldn't be happier with the result. This is truly one of the best decisions that we have ever made for our organization. I would highly recommend StaffWiz to anyone that is looking for quality individuals to grow their organization.</p>
                            </div>
                        </div>
                    </div>
                        <div class="item">
                            <div class="card">
                                <div class="card-body">
                                    <img class="img-fluid" src="assets/images/rating.svg" />
                                    <h5>Amelie Peterson - TMC inc</h5>
                                    <p>hesitantly hired a VA ( virtual assistant) for my E commerce business through Staffwiz in the Philippines, I was skeptical that an offshore staff would not be comparable to an American employee, but I have to say my VA proved me wrong she is simply phenomenal.</p>
                                </div>
                            </div>
                        </div>

                        <div class="item">
                            <div class="card">
                                <div class="card-body">
                                    <img class="img-fluid" src="assets/images/rating.svg" />
                                    <h5>D Bhatta - Bhatta Law llc</h5>
                                    <p>My Law firm gets a lot of inbound calls concerning immigration matters, we needed a couple of customer support reps to assist us with this. staffwiz found a great team for us we are very happy with them.</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="card">
                                <div class="card-body">
                                    <img class="img-fluid" src="assets/images/rating.svg" />
                                    <h5>Pravez Gondal - Gondal LLC</h5>
                                    <p>Staffwiz helped me find medical billing staff for my Medical staffing business at a great rate, they really match you with a team that has the right skillset youneed for your business. I hope to hire more staff in the next 3 months.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="scale-business">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-8">
                            <h1 className="heading">Ready to scale your business?</h1>
                            <h4>Let us help you set up for success</h4>
                            <h5>Call now</h5>
                           <a href="/schedule-call" className="team-btn mt-4 mb-5">FREE CONSULTATION</a>
                        </div>
                        <div className="col-lg-4">
                            <img className="img-fluid " src="assets/images/scale.png"></img>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
export default Industries;
