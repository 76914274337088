import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { format } from 'date-fns';
import {Helmet} from "react-helmet";
import "./index.css"

const DetailPage = (props) => {

    const params = useParams();
    const [loading, setLoading] = useState(true);
    const [blogdata, setblogdata] = useState([])

    const loadblogdata = async () => {
        let catagiorydata;

        const res = await fetch("https://www.sellmymiles.com/staffwiz/wp-json/wp/v2/posts?_embed&slug=" + params.name, {
            method: "GET",
            statusCode: 200,
            headers: {
                 'Content-Type':'application/json',
            },
        })
        let data = await res.json();
        if (data) {
            setblogdata(data);
            console.log(blogdata);
        }
    }

    useEffect(() => {
        loadblogdata()
    }, []);

    const decodeHtmlCharCodes = (str) => {
        return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
            return String.fromCharCode(charCode);
        });
    }

    return (
        <>
        
            {blogdata && blogdata.length ?
                <section className="blog-details"> 
                 <Helmet>
            <title>{decodeHtmlCharCodes(blogdata[0].title.rendered)} | StaffWiz</title>
            <meta name="description" content={decodeHtmlCharCodes(blogdata[0].title.rendered)} />
        </Helmet>
                    <div className="container">
                        <h1>{decodeHtmlCharCodes(blogdata[0].title.rendered)}</h1>
                        <h6> {format(blogdata[0].date.split("T")[0], 'MMMM dd, yyyy')}</h6>
                        <img className="details-img" src={blogdata[0]._embedded['wp:featuredmedia']['0'].source_url}></img>
                        <div className="row mt-4">
                            <div className="col-12 col-md-12 col-lg-12">
                                <div className="info-details ">
                                    <div dangerouslySetInnerHTML={{ __html: blogdata[0].content.rendered }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section> :
                null}

        </>
    );
};

export default DetailPage;