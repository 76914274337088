import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/readyToScale"
import ClientsWords from "../../../component/ClientWord";
import StrategicPartners from "../../../component/StrategicPartners";
import {Helmet} from "react-helmet";

const TechSupport = () => {
    return (
        <>
        <Helmet>
            <title>Technical Support Outsourcing In USA - StaffWiz</title>
            <meta name="description" content="Squad up! Let us provide top-notch technical support to solve simple to complex issues, so that your customers can enjoy your solutions with ease. We offer 24x7 support!" />
        </Helmet>
            <section className="home-banner lndg-banner">
                <div className="container">
                    <h1>Hire your world class Tech Support Team </h1>
                    <h3 className="my-4">Solutions for startups & small-medium businesses</h3>
                    <div className="row align-items-end">

                        <div className="col-lg-4 right-info">


                            <div className="row px-lg-3 main-catagiory">
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Save 70% on staffing costs</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Hire in weeks not months</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Boost productivity</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Quality & revenue guaranteed </b></h5>
                                </div>
                            </div>
                            <div className="home-rating-banner mt-4">
                                <img src="assets/images/SW Website asset13.png" alt="review stars"></img>
                                <img src="assets/images/HIPAA-COMPLIANT 1.png" alt="review stars"></img>
                                <img src="assets/images/SW Website asset15.png" alt="review stars"></img>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img
                                src="assets/images/home-banner1.png"
                                className="img-fluid" alt="instruction"
                            ></img>
                        </div>
                        <div className="col-lg-4">
                            <GetLandingForm heading={"HIRE NOW"} />
                        </div>
                    </div>
                </div>
            </section>
            {/* details and services */}
            <section className="who-are">
                <div className="container">
                    <h2 className="heading text-center w-100 mb-0">Hire Tech Support experts starting from $9/hr*</h2>
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="lndng-auto">
                                <p className="text-center mt-0 mb-1">Need a Tech Support expert … Or Two? Or Maybe a Whole Team?</p>
                                <p className="text-center mt-0">Let us show you how technical support Outsourcing can fill your employment needs at a fraction of the cost.</p>
                                <p className="text-center mt-0">Staffwiz will create customized tech support teams tailored to meet the unique needs of your customers.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-8 landing-hire-bar">
                            <div className="bar1">No contracts</div>
                            <div className="bar2">No obligations</div>
                            <div className="bar3">No hidden fees</div>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Managing complex technical issues.</h4>
                            <p>
                                Technical support outsourcing helps users with the installation of new software or hardware, resolving errors and glitches to ensure customers can use the product.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Expertise</h4>
                            <p>
                                For customer technical issues, expert support is vital. Our tech support agents solve complex problems, ensuring customer satisfaction. By outsourcing to Helpware, your users get accurate advice from knowledgeable experts, preventing future issues.
                            </p>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Logic & Creativity</h4>
                            <p>
                                Our technical support professionals excel at blending logic and creativity to solve unique problems in outsourced technical support services. They employ logical reasoning to troubleshoot issues and devise innovative solutions.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Prompt Responses</h4>
                            <p>
                                In a world full of gadgets and software, retaining customer loyalty is challenging. The key is to offer quick and expert technical support, which our agents deliver reliably.
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <BenifitsOfOutsourcing title={"Technical Suppor"} />

            <HiringIsEasy />
<p className="container" >Unlock top-tier IT talent with our specialized staffing solutions. Share your details to access premium IT placement and recruitment services tailored to your needs. Let us connect you with the best in the industry, ensuring your IT team's success and growth.</p>
            <ClientsWords />
            <StrategicPartners />

            {/* <HireTalent /> */}
            <ReadyToScale />


        </>

    );
};
export default TechSupport;
