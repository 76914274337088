import React, { useState } from "react";
import { apis } from "../services/Api"

const GetStartForm = (props) => {
    const [inputValue, setInputValue] = useState({});
    const [loader, setLoader] = useState(false)

    const handleInputChange = (event) => {
        setInputValue({
            ...inputValue,
            [event.target.name]: event.target.value
        });
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        console.log("inputValue", inputValue)
        const data = {
            name: inputValue?.name,
            lastName: inputValue?.lastName,
            companyName: inputValue?.companyName,
            businessEmail: inputValue?.businessEmail,
            phoneNumber: inputValue?.phoneNumber,
            industries: inputValue?.industries,
            numStaffNeeded: inputValue?.numStaffNeeded,
            serviceType: inputValue?.serviceType,
            teamSetupTime: inputValue?.teamSetupTime,
        };
        if (Object.values(data).every(value => value !== undefined)) {
            const res = await apis.GetStart(data);
            if (res) {
                setLoader(false)
                // console.log("res", res)
                window.scroll({ top: 0, behavior: "smooth" });
                window.location.href = '/get-started-Thankyou';
            }
        }
    };
    return (
        <>
            <div className="banner-from">
                <div className="card border-0">
                    {props?.heading ?
                        <div className="card-header">
                            <h5 className="py-1">{props?.heading}</h5>
                        </div>
                        : null}
                    <form onSubmit={e => handleSubmit(e)}>
                        <div className="card-body">
                            {props?.subheading ?
                                <h4 >{props?.subheading}</h4>
                                : null}

                            <div className="row">
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Name"
                                            name="name"
                                            value={inputValue?.name}
                                            onChange={handleInputChange}
                                            pattern="[^0-9]*"
                                            title="Please enter a valid text without numbers."
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Last Name"
                                            name="lastName"
                                            value={inputValue?.lastName}
                                            onChange={handleInputChange}
                                            pattern="[^0-9]*"
                                            title="Please enter a valid text without numbers."
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Company Name"
                                            name="companyName"
                                            value={inputValue?.companyName}
                                            onChange={handleInputChange}
                                            pattern="[^0-9]*"
                                            title="Please enter a valid text without numbers."
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div class="form-group">
                                        <input
                                            type="email"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Business Email"
                                            name="businessEmail"
                                            value={inputValue?.businessEmail}
                                            onChange={handleInputChange}
                                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                                            title="Please enter a valid email address."
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Phone number"
                                            name="phoneNumber"
                                            value={inputValue?.phoneNumber}
                                            onChange={handleInputChange}
                                            pattern="[0-9]{10}"
                                            title="Please enter a valid 10-digit phone number."
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <select class=" form-control" aria-label="Default select example"
                                            name="industries"
                                            value={inputValue?.industries}
                                            onChange={handleInputChange} required>

                                            <option selected value="" disabled>Industries</option>
                                            <option value="Healthcare service">Healthcare service</option>
                                            <option value="Professional services">Professional services</option>
                                            <option value="Contact Center">Contact Center</option>
                                            <option value="Financial Service">Financial Service</option>
                                            <option value="Admin and data">Admin and data</option>
                                            <option value="Creative Service">Creative Service</option>
                                            <option value="Tech Support">Tech Support</option>
                                            <option value="Travel Service">Travel Service</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    <div class="form-group">
                                        <select class=" form-control" aria-label="Default select example"
                                            name="numStaffNeeded"
                                            value={inputValue?.numStaffNeeded}
                                            onChange={handleInputChange} required>

                                            <option selected value="" disabled>Team size</option>
                                            <option value="20+">20 +</option>
                                            <option value="10 - 20">10 - 20</option>
                                            <option value="10 - 5">10 - 5</option>
                                            <option value="3 - 4">3 - 4</option>
                                            <option value="1 - 2">1 - 2</option>
                                            <option value="Not Sure">Not Sure</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <select class=" form-control" aria-label="Default select example"
                                            name="serviceType"
                                            value={inputValue?.serviceType}
                                            onChange={handleInputChange} required>

                                            <option selected value="" disabled>Service Type</option>
                                            <option value="Inboaund">Inboaund</option>
                                            <option value="Outbound">Outbound</option>
                                            <option value="BPO">BPO</option>
                                            <option value="General Inquiry">General Inquiry</option>
                                            <option value="Partnership">Partnership</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div class="form-group">
                                        <select class=" form-control" aria-label="Default select example"
                                            name="teamSetupTime"
                                            value={inputValue?.teamSetupTime}
                                            onChange={handleInputChange} required>

                                            <option selected value="" disabled>How soon would like to set up the team?</option>
                                            <option value="Immediately">Immediately</option>
                                            <option value="In the upcoming 30 days">In the upcoming 30 days</option>
                                            <option value="Immediately">Immediately</option>
                                            <option value="In the upcoming 90 days">In the upcoming 90 days</option>
                                            <option value="Still gathering information">Still gathering information.</option>
                                        </select>
                                    </div>
                                    {/* <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="How soon would like to set up the team?"
                                            name="teamSetupTime"
                                            value={inputValue?.teamSetupTime}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div> */}
                                </div>
                            </div>
                            <div className="text-center">
                                <button className={`team-btn ${loader ? "disable" : null}`}>Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
export default GetStartForm;
