import React from "react";
//import GetStartForm from "./getStartForm"

const HiringIsEasy = () => {
    return (
        <section className="advantages">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 mx-auto hire-border">
                        <h1 className="heading">Hiring is Easy</h1>
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="hire-number">
                                    <span>1</span><img src="assets/images/Line 16.png"></img>
                                    <h3>Discovery Call</h3>
                                    <p>During the call, we will listen, discuss, and understand your exact business requirements.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="hire-number">
                                    <span>2</span><img src="assets/images/Line 16.png"></img>
                                    <h3>Sourcing</h3>
                                    <p>After gaining a clear understanding of your requirements, our talent acquisition team will begin the process of finding exceptional sales reps.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="hire-number">
                                    <span>3</span><img src="assets/images/Line 16.png"></img>
                                    <h3>Candidate Review</h3>
                                    <p>With your prepared lead time, we'll present you with a list of candidates for your review, helping you find the perfect outsourced sales team.</p>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="hire-number">
                                    <span>4</span><img src="assets/images/Line 16.png"></img>
                                    <h3>Start Work</h3>
                                    <p>After selecting your outsourced sales team, work directly with them and scale your needs by hiring more reps when ready. Of course, we are there with you before, during, and after the process.</p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )

}
export default HiringIsEasy;