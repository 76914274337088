import React from "react"
import "./index.css"
import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <>
            <footer>
                <div class="container">
                    {/* <div class="footer-logo"></div> */}
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="contact-info">
                            <a href="/"><img src="assets/images/flogo.png" /></a>
                                <h6>USA Address:</h6>
                                <p>262 West 38 Street,Suite 1001 New York City,NY 10018, USA</p>
                                <h6>Philippine Address:</h6>
                                <p>7F, Finman Centre131 Tordesillas Street. Salcedo Vill, Bel-AirMakati City, Philippines</p>
                                <h7>Phone: <span>Phone: 1888-999-4311</span></h7>
                                <h7>Phone: <span>(Philippine):02-7823-3949</span></h7>
                                <h7>Email : <span> sales@staffwiz.com</span></h7>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <h5>USEFUL LINKS</h5>
                            <ul class="pages-link">
                                <li><a href="/about-us">About Us</a></li>
                                <li><a href="/faq">FAQs</a></li>
                                <li><a href="/contact">Contact Us</a></li>
                                <li><a href="/job-roles">JOB ROLES</a></li>
                                <li><a href="/PrivacyPolicy">Privacy Policy</a></li>
                                <li><a href="/TermsandConditions">Terms and Conditions</a></li>

                            </ul>
                        </div>
                        <div class="col-lg-3">
                            <h5>SUBSCRIBE</h5>
                            <p>Sign up for our mailing list to <br />get latest updates and offers.</p>
                            <div class="form-group">
                                <div class="input-group">
                                    <input type="text" class="form-control" id="validationTooltipUsername" aria-describedby="validationTooltipUsernamePrepend" placeholder="Email Address" />
                                    <div class="input-group-prepend">
                                        <button class="btn-brnad">Go</button>
                                    </div>

                                </div>
                            </div>
                            <h5><a href="/blog" style={{ 'color': '#fff' }}>Blog</a></h5>
                            <h5><a href="/case-studies" style={{ 'color': '#fff' }}>Case Study</a></h5>

                        </div>
                        <div class="col-lg-3">
                            <h5>FOLLOW US</h5>
                            <ul class="social-link">
                                <li><a href="https://www.facebook.com/StaffWizGlobalOutsourcing"><img src="assets/images/social-icon (1).svg" /></a></li>
                                <li><a href="https://www.instagram.com/staffwiz_us/"><img src="assets/images/social-icon (2).svg" /></a></li>
                                <li><a href="https://www.linkedin.com/company/staffwiz/"><img src="assets/images/social-icon (3).svg" /></a></li>
                                <li><a href="https://www.youtube.com/@staffwiz3909"><img src="assets/images/social-icon (4).svg" /></a></li>
                            </ul>
                            <h5>About Staffwiz</h5>
                            <p className="about">At StaffWiz, we're not just a BPO call center; we're your strategic ally for operational efficiency and workforce optimization. Committed to innovation, customer satisfaction, and expertise, we're a trusted leader in business process outsourcing.
</p>
                        </div>
                        <div class="col-lg-3"></div>
                        <div class="col-lg-6 ftimg">
                        <img src="assets/images/advantage (3).png" />
                        <img src="assets/images/advantage (2).png" />
                        <img src="assets/images/advantage (1).png" />
                        <img src="assets/images/advantage (4).png" />
                        </div>
                        <div class="col-lg-3"></div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;