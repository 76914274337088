import React from "react";
import "../index.css";
import ReviewForm from "../../../component/reviewForm"
import HireTalent from "../../../component/hireTalent"
import { PopupButton } from "react-calendly";
import { APP_CONFIG } from "../../../config/config";
import {Helmet} from "react-helmet";

const WriteReviews = () => {
    return (
        <>
        <Helmet>
            <title>Global Outsourcing & Offshoring in USA - StaffWiz</title>
            <meta name="description" content="With a track record since 2000, StaffWiz delivers technology-driven offshore outsourcing solutions to global companies. Ready to scale your business? Contact us now and unlock your growth potential!" />
        </Helmet>
            <section className="home-banner innerHome-banner">
                <div className="container">
                    <div className="row ">
                        <div className="col-lg-10 mx-auto">
                            <div className="row">
                            <div className="col-lg-3"></div>
                                <div className="col-lg-6">

                                    <ReviewForm />

                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
    

        </>
    );
};
export default WriteReviews;
