import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/serviceReadyToScale"
import ClientsWords from "../../../component/ClientWord";
import ServiceWhyChoose from "../../../component/serviceWhyChoose";
import ServiceBenefits from "../../../component/serviceBenefits";
import {Helmet} from "react-helmet";

const ThirdPartyVerification = () => {
    return (
        <>
        <Helmet>
            <title>Third Party Verification | Verification Services | 3rd Party Verification Services - Staffwiz</title>
            <meta name="description" content="Ensure accuracy with Staffwiz's third party verification services. We provide reliable verification services and comprehensive 3rd party verification services for your business needs." />
        </Helmet>
           <section className="home-banner  careers-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6">
                                    <h1>
                                        Third-Party{'\n'} Verification
                                    </h1>
                                    <p>Partner with StaffWiz to boost credibility, minimize risk, and build trust with clients through expert third-party verification. Our tailored services ensure accuracy and compliance, providing a reliable solution for establishing and maintaining industry trust. Opt for StaffWiz for seamless 3rd party verification, paving the way for sustainable success.</p>
                                    
                                </div>
                                <div className="col-lg-6 right-info">
                                    <img
                                        className="img-fluid"
                                        src="assets/images/14 1.png"
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    <section className="service-form">
    <img
                                        className="img-fluid dsgn-img1"
                                        src="assets/images/BPO Service Materials 1.png"
                                    ></img>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6 pd30">
                                    <GetLandingForm heading={"Elevate Your Business with Third-Party Verification by Staffwiz"} />
                                </div>
                        
                                <div className="col-lg-6 right-inf pd30">
                                <ServiceWhyChoose title={"Third-Party Verification"} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
         <img
                                        className="img-fluid dsgn-img2"
                                        src="assets/images/2 565.png"
                                    ></img>
            </section>
     <ServiceBenefits title={"third-party verification"} />
     <div className="container realizeP">
                    <div className="row">
                    <div className="col-lg-9 mx-auto">
     <p>Elevate trust and credibility with StaffWiz's expert third party verification services. Our tailored verification services, including 3rd party verification services, ensure accuracy and compliance for your business needs. Contact StaffWiz today for reliable solutions that enhance your reputation and build lasting trust.</p>
           </div>
   <div className="col-lg-3 mx-auto">
   </div>
           </div>
            </div>
             <ClientsWords />
           

            {/* <HireTalent /> */}
            <ReadyToScale />


        </>

    );
};
export default ThirdPartyVerification;
