import React, { useState } from "react";
import { apis } from "../services/Api"

const GetStartForm = (props) => {
    const [inputValue, setInputValue] = useState({});
    const [loader, setLoader] = useState(false)

    const handleInputChange = (event) => {
        setInputValue({
            ...inputValue,
            [event.target.name]: event.target.value
        });
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        console.log("inputValue", inputValue)
        const data = {
            firstName: inputValue?.firstName,
            lastName: inputValue?.lastName,
            companyName: inputValue?.companyName,
            email: inputValue?.email,
            phoneNumber: inputValue?.phoneNumber,
            reason: inputValue?.reason,
            staff_required: inputValue?.staff_required,
        };
        if (Object.values(data).every(value => value !== undefined)) {
            const res = await apis.ContactUs(data);
            if (res) {
                setLoader(false)
                 console.log("res", res)
                window.scroll({ top: 0, behavior: "smooth" });
                window.location.href = '/get-started-Thankyou';
            }
        }
    };
    return (
        <>
            <div className="banner-from">
                <div className="card border-0">
                   
                    <form onSubmit={e => handleSubmit(e)}>
                        <div className="card-body">
                            {props?.subheading ?
                                <h4 >{props?.subheading}</h4>
                                : null}

                            <div className="row">
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="First Name"
                                            name="firstName"
                                            value={inputValue?.firstName}
                                            onChange={handleInputChange}
                                            pattern="[^0-9]*"
                                            title="Please enter a valid text without numbers."
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Last Name"
                                            name="lastName"
                                            value={inputValue?.lastName}
                                            onChange={handleInputChange}
                                            pattern="[^0-9]*"
                                            title="Please enter a valid text without numbers."
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Company Name"
                                            name="companyName"
                                            value={inputValue?.companyName}
                                            onChange={handleInputChange}
                                            pattern="[^0-9]*"
                                            title="Please enter a valid text without numbers."
                                            required
                                        />
                                    </div>
                                </div>
                    <div className="col-lg-12 form-group">
                      <select
                        className="form-control"
                        required
                        name="reason"
                        id="reason"
                        value={inputValue?.reason}
                        onChange={handleInputChange}
                      >
                        <option hidden="hidden" selected="selected" value="">
                          Reason*
                        </option>
                        <option>I am looking to build an offshore team</option>
                        <option>
                          I am interested in employment opportunities
                        </option>
                      </select>
                    </div>
                  
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Phone number"
                                            name="phoneNumber"
                                            value={inputValue?.phoneNumber}
                                            onChange={handleInputChange}
                                            pattern="[0-9]{10}"
                                            title="Please enter a valid 10-digit phone number."
                                            required
                                        />
                                    </div>
                                </div>
                                 <div className="col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="email"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Email"
                                            name="email"
                                            value={inputValue?.email}
                                            onChange={handleInputChange}
                                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                                            title="Please enter a valid email address."
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12 form-group">
                      <input
                        type="text"
                        required
                        className="form-control"
                        name="staff_required"
                        id="staff_required"
                        value={inputValue?.staff_required}
                        onChange={handleInputChange}
                        placeholder="Numer of staff required*"
                      />
                    </div>
                            </div>
                            <div className="text-center">
                                 {!loader && <button className={`team-btn ${loader ? "disable" : null}`}>Send Email</button>}
                                {loader && <img src="/assets/images/Loader.gif" alt="loader" className="w-100"/>}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};
export default GetStartForm;
