import React from "react";
//import GetStartForm from "./getStartForm"

const ServiceBenefits = (props) => {
    return (
             <section className="service-benefits">
    <img
                                        className="img-fluid dsgn-img3"
                                        src="assets/images/BPO Service Materials 2.png"
                                    ></img>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6 pd30">
                                   <h2>Realizing the Benefits</h2>
                           <p>By partnering with Staffwiz for BPO {props?.title}, your business can realize several benefits:</p>
                   <h3><img
                                        className="img-fluid"
                                        src="assets/images/2 561.png"
                                    ></img>Enhanced Customer Satisfaction</h3>
           <h3><img
                                        className="img-fluid"
                                        src="assets/images/2 561.png"
                                    ></img>Increased Efficiency</h3>
   <h3><img
                                        className="img-fluid"
                                        src="assets/images/2 561.png"
                                    ></img>Cost Savings</h3>
   <h3><img
                                        className="img-fluid"
                                        src="assets/images/2 561.png"
                                    ></img>Business Growth</h3>
                                </div>
                        
                                <div className="col-lg-6 pd30">
                               <img
                                        className="img-fluid"
                                        src="assets/images/16 2.png"
                                    ></img>
                                    </div>
                            </div>
                            <p style={{padding:'20px 20% 0px 0px'}}>{props?.desc}</p>
                        </div>
                    </div>
                </div>
            </section>
    )

}
export default ServiceBenefits;