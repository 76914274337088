import React from "react";
import "./index.css";
import "../Home/index.css";
import ClientsWord from "../Home/ClientWord";
import StrategicPartners from "../Home/StrategicPartners";
import { PopupButton } from "react-calendly";
import { useCalendlyEventListener, InlineWidget } from "react-calendly";
import { APP_CONFIG } from "../../config/config";
import { useNavigate } from 'react-router-dom';
import { Helmet } from "react-helmet";

const ScheduleCall = () => {
    const navigate = useNavigate();
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log("onProfilePageViewed"),
    onDateAndTimeSelected: () => console.log("onDateAndTimeSelected"),
    onEventTypeViewed: () => console.log("onEventTypeViewed"),
    onEventScheduled: (e) =>     navigate('/thankyou-for-booking'),
  });

    return (
        <>
        
         <Helmet>
        <title>Free Consultation Form | Staffwiz</title>
</Helmet>
        <div className="schedule-call-container">
            <div className="container">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                        <div className="team-popup-cotent schedule-call">

                            <div className="bill-heading">
                                <div className="position-relative margin-t20px text-center">
                                <img src="assets/images/awesome.png" alt="" />
                                    <h1 className=" font-weight-bold">
                                        Schedule your 15-minute free consultation.
                                    </h1>
                                    <h3 >We promise you it will be worth it!</h3>
                                   
                                </div>
                            </div>
                                
                        </div>
                    </div>
            </div>
            <div className="row">
           
            <div className="col-lg-12">
           
            <InlineWidget className="nav-link back-call-btn cursor-pointer" url={APP_CONFIG.CALENDELY.url} styles={{height: '900px',marginTop: '-70px'}}/></div>
                </div>
            </div>



        </div>

            <ClientsWord/>
            <StrategicPartners className="my-5" />
        </>
    )
}

export default ScheduleCall;