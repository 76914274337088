import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/serviceReadyToScale"
import ClientsWords from "../../../component/ClientWord";
import ServiceWhyChoose from "../../../component/serviceWhyChoose";
import ServiceBenefits from "../../../component/serviceBenefits";
import {Helmet} from "react-helmet";

const AppointmentSetting = () => {
    return (
        <>
        <Helmet>
            <title>Appointment Setting | Appointment Setting Services | B2B Appointment Setting | Remote Appointment Setters - Staffwiz</title>
            <meta name="description" content="Boost your business with expert appointment setting services. Staffwiz provides B2B appointment setting and skilled remote appointment setters to streamline your sales process." />
        </Helmet>
           <section className="home-banner  careers-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6">
                                    <h1>
                                        Appointment{'\n'} Setting
                                    </h1>
                                    <p>Efficient appointment setting is a cornerstone of success for BPO companies. With Staffwiz, you can streamline your appointment setting process, boost productivity, and strengthen client relationships.</p>
                                    
                                </div>
                                <div className="col-lg-6 right-info">
                                    <img
                                        className="img-fluid"
                                        src="assets/images/5 1 (1).png"
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    <section className="service-form">
    <img
                                        className="img-fluid dsgn-img1"
                                        src="assets/images/BPO Service Materials 1.png"
                                    ></img>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6 pd30">
                                    <GetLandingForm heading={"Elevate Your Business with Appointment Setting by Staffwiz"} />
                                </div>
                        
                                <div className="col-lg-6 right-inf pd30">
                                <ServiceWhyChoose title={"Appointment Setting"} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
         <img
                                        className="img-fluid dsgn-img2"
                                        src="assets/images/2 565.png"
                                    ></img>
            </section>
     <ServiceBenefits title={"appointment setting"} desc={"Enhance your sales pipeline with our expert appointment setting solutions. Staffwiz's appointment setting services are designed to drive results, whether you need B2B appointment setting or support from our skilled remote appointment setters. Let us streamline your sales process and connect you with the right opportunities."} />
             <ClientsWords />
           

            {/* <HireTalent /> */}
            <ReadyToScale />


        </>

    );
};
export default AppointmentSetting;
