import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/readyToScale"
import ClientsWords from "../../../component/ClientWord";
import StrategicPartners from "../../../component/StrategicPartners";
import {Helmet} from "react-helmet";

const HR = () => {
    return (
        <>
         <Helmet>
            <title>HR Outsourcing | Virtual HR Solutions in USA - StaffWiz</title>
            <meta name="description" content="StaffWiz is one of the best HR Outsourcing Companies offering top-notch virtual HR services to drive your business forward. Contact Today!" />
        </Helmet>
            <section className="home-banner lndg-banner">
                <div className="container">
                    <h1>Hire your world class HR Specialist </h1>
                    <h3 className="my-4">Solutions for startups & small-medium businesses</h3>
                    <div className="row align-items-end">

                        <div className="col-lg-4 right-info">


                            <div className="row px-lg-3 main-catagiory">
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Save 70% on staffing costs</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Hire in weeks not months</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Boost productivity</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Quality & revenue guaranteed </b></h5>
                                </div>
                            </div>
                            <div className="home-rating-banner mt-4">
                                <img src="assets/images/SW Website asset13.png" alt="review stars"></img>
                                <img src="assets/images/HIPAA-COMPLIANT 1.png" alt="review stars"></img>
                                <img src="assets/images/SW Website asset15.png" alt="review stars"></img>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img
                                src="assets/images/home-banner1.png"
                                className="img-fluid" alt="instruction"
                            ></img>
                        </div>
                        <div className="col-lg-4">
                            <GetLandingForm heading={"HIRE NOW"} />
                        </div>
                    </div>
                </div>
            </section>
            {/* details and services */}
            <section className="who-are">
                <div className="container">
                    <h2 className="heading text-center w-100">Hire HR experts starting from $6/hr*</h2>
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <p className="lndng-auto">Need a HR expert … Or Two? Or Maybe a Whole Team?</p>
                            <p className="lndng-auto">From HR outsourcing to customer support, we've got you covered. Let us show you how efficiency meets affordability in transforming your business.</p>
                            <p className="lndng-auto">Staffwiz, together with your dedicated HR Manager, enables you to automate and streamline various aspects of your HR processes. This includes simplifying recruitment, automating onboarding procedures, implementing efficient policies, and even automating employee training.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-8 landing-hire-bar">
                            <div className="bar1">No contracts</div>
                            <div className="bar2">No obligations</div>
                            <div className="bar3">No hidden fees</div>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Employee Management</h4>
                            <p>
                                Support performance management, track/manage employee leaves and absences, coordinate employee training and development, respond to inquiries/concerns, and assist with disciplinary actions and conflict resolution.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Recruitment Support</h4>
                            <p>
                                This includes posting job openings, reviewing resumes, coordinating interviews and assessments, conducting background checks, verifying references, and assisting with offer letters and contracts.
                            </p>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Employee Communication</h4>
                            <p>
                                Drafting and distributing internal HR communications, assisting with employee engagement initiatives, coordinating employee events and recognition programs, and managing HR announcements and updates.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>HR Reporting and Analytics</h4>
                            <p>
                                The tasks include analyzing HR data, identifying trends, assisting with workforce planning, ensuring HR compliance, and researching best practices and industry trends.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
<p className="container" >Searching for HR solutions that fit like a glove? Share your details with us. Our HR outsourcing expertise ensures tailored strategies for your success. Connect today to unlock the best HR outsourcing companies that cater to your unique needs.</p>
            <BenifitsOfOutsourcing title={"HR Experts"} />

            <HiringIsEasy />
<p className="container">Navigate the complexities of HR with ease. Share your details for personalized small business HR services that bring efficiency to your operations. Let us craft solutions tailored to you, powered by HR BPO expertise. Connect now for a smoother, more effective business journey.</p>
            <ClientsWords />
            <StrategicPartners />

            {/* <HireTalent /> */}
            <ReadyToScale />


        </>

    );
};
export default HR;
