import React, { useState } from "react";
import "./index.css";
import { apis } from "../../services/Api"
import {Helmet} from "react-helmet";

const Careers = () => {
    const [formData, setFormData] = useState({})
    const [loader, setLoader] = useState(false)

    const handleInput = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.files[0]
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        const careersData = {
            firstname: formData?.firstname,
            lastname: formData?.lastname,
            email: formData?.email,
            secondaryemail: formData?.secondaryemail,
            phone: formData?.phone,
            mobile: formData?.mobile,
            street: formData?.street,
            zip_postal_code: formData?.zip_postal_code,
            state_province: formData?.state_province,
            country: formData?.country,
            experience: formData?.experience,
            highest_qualification: formData?.highest_qualification,
            current_job_title: formData?.current_job_title,
            current_employer: formData?.current_employer,
            expected_salary: formData?.expected_salary,
            current_salary: formData?.current_salary,
            skill_set: formData?.skill_set,
            additional_info: formData?.additional_info,
            skypeid: formData?.skypeid,
            twitter: formData?.twitter,
            resume: formData?.resume,
            city: formData?.city,
        };


        const res = await apis.AddCandidate(careersData);
        if (res) {
            // window.scroll({ top: 0, behavior: "smooth" });
            // window.location.href = '/contact-thankyou';
            console.log("res", res)
            setLoader(false)
            window.scroll({ top: 0, behavior: "smooth" });
            window.location.href = '/careers-Thankyou';
        }



    }
    return (
        <>
        <Helmet>
            <title>Career | StaffWiz</title>
        </Helmet>
            <section className="home-banner  careers-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6">
                                    <h1>
                                        You’re one step closer to the job you deserve
                                    </h1>
                                    <p>We will see you through your better career path.</p>
                                    <div className="servies-list ">
                                        <ul className="p-0">
                                            <li>
                                                <span>
                                                    <i class="fas fa-long-arrow-alt-right"></i>
                                                </span>{" "}
                                                Customer Support
                                            </li>
                                            <li>
                                                <span>
                                                    <i class="fas fa-long-arrow-alt-right"></i>
                                                </span>{" "}
                                                Executive/Admin Support
                                            </li>
                                            <li>
                                                <span>
                                                    <i class="fas fa-long-arrow-alt-right"></i>
                                                </span>{" "}
                                                Technical Support
                                            </li>
                                            <li>
                                                <span>
                                                    <i class="fas fa-long-arrow-alt-right"></i>
                                                </span>{" "}
                                                Sales & Marketing Support
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-6 right-info">
                                    <img
                                        className="img-fluid"
                                        src="assets/images/SW why us (3) 1.png"
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section className="global-employer the-solution how-work2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <h1 className="heading ">How it works?</h1>
                            <div className="row mt-5">
                                <div className="col-lg-4 ">
                                    <div className="uper-card">
                                        <img src="assets/images/works (1).png"></img>
                                        <p>Fill out information about yourself, and post your resume.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 ">
                                    <div className="uper-card">
                                        <img src="assets/images/works (2).png"></img>
                                        <p>Upon passing the interview, we will profile your skills against the right company and the right role.</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 ">
                                    <div className="uper-card">
                                        <img src="assets/images/works (3).png"></img>
                                        <p>Use shall be presented to the hiring company, and from there, you start a new career.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <h2>
                                        Why work with Staffwiz?
                                    </h2>
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="info-box">
                                                <img
                                                    className="img-fluid"
                                                    src="assets/images/check-green.svg"
                                                ></img>
                                                <h6>Competitive Compensation Package</h6>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="info-box">
                                                <img
                                                    className="img-fluid"
                                                    src="assets/images/check-green.svg"
                                                ></img>
                                                <h6>Statutory benefits</h6>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="info-box">
                                                <img
                                                    className="img-fluid"
                                                    src="assets/images/check-green.svg"
                                                ></img>
                                                <h6>Load/Data allowance</h6>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="info-box">
                                                <img
                                                    className="img-fluid"
                                                    src="assets/images/check-green.svg"
                                                ></img>
                                                <h6>Enticing Career Development Opportunities</h6>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="info-box">
                                                <img
                                                    className="img-fluid"
                                                    src="assets/images/check-green.svg"
                                                ></img>
                                                <h6>Growth-conducive, diverse environment</h6>
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="info-box">
                                                <img
                                                    className="img-fluid"
                                                    src="assets/images/check-green.svg"
                                                ></img>
                                                <h6>Prime Location at the heart of Makati</h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="growing-together">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <h1 className="heading ">We believe in growing together.</h1>
                            <p className="section-disc">
                                At Staffwiz, we build a culture that nurture each other's professional advancement.If you’re ready to grow while helping global companies grow bigger, you will fit right in.
                            </p>
                            <form onSubmit={handleSubmit}>

                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="sub-heading">Basic Info</h3>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby="" placeholder="First Name"
                                                name="firstname" value={formData.firstname} onChange={handleInput}
                                                pattern="[^0-9]*" title="Please enter a valid text without numbers." required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Last Name" name="lastname" value={formData.lastname} onChange={handleInput}
                                                pattern="[^0-9]*" title="Please enter a valid text without numbers." required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Email Address"
                                                name="email" value={formData.email} onChange={handleInput}
                                                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" title="Please enter a valid email address." required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Secondary Email"
                                                name="secondaryemail" value={formData.secondaryemail} onChange={handleInput}
                                                pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}" title="Please enter a valid email address." required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Contact Number"
                                                name="phone" value={formData.phone} onChange={handleInput}
                                                pattern="[0-9]+" title="Please enter a valid phone number." required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Mobile"
                                                name="mobile" value={formData.mobile} onChange={handleInput}
                                                pattern="[0-9]+" title="Please enter a valid mobile number." required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="sub-heading">Address Information</h3>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Street"
                                                name="street" value={formData.street} onChange={handleInput} required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Zip/Postal Code"
                                                name="zip_postal_code" value={formData.zip_postal_code} onChange={handleInput}
                                                pattern="[0-9]+" title="Please enter a valid Zip/Postal Code." required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="City"
                                                name="city" value={formData.city} onChange={handleInput}
                                                pattern="[^0-9]*" title="Please enter a valid text without numbers." required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="State/Province"
                                                name="state_province" value={formData.state_province} onChange={handleInput} required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Country"
                                                name="country" value={formData.country} onChange={handleInput}
                                                pattern="[^0-9]*" title="Please enter a valid text without numbers." required />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12">
                                        <h3 className="sub-heading">Professional Details</h3>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Experience in Years"
                                                name="experience" value={formData.experience} onChange={handleInput}
                                                pattern="[0-9]+" title="Please enter a valid number" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Current Job Title"
                                                name="current_job_title" value={formData.current_job_title} onChange={handleInput}
                                                pattern="[^0-9]*" title="Please enter a valid text without numbers." required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Highest Qualification"
                                                name="highest_qualification" value={formData.highest_qualification} onChange={handleInput}
                                                pattern="[^0-9]*" title="Please enter a valid text without numbers." required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Current Employer"
                                                name="current_employer" value={formData.current_employer} onChange={handleInput}
                                                pattern="[0-9]+" title="Please enter a valid number" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Expected Salary"
                                                name="expected_salary" value={formData.expected_salary} onChange={handleInput}
                                                pattern="[0-9]+" title="Please enter a valid number" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Current Salary"
                                                name="current_salary" value={formData.current_salary} onChange={handleInput}
                                                pattern="[0-9]+" title="Please enter a valid number" required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Skill Set"
                                                name="skill_set" value={formData.skill_set} onChange={handleInput}
                                                pattern="[^0-9]*" title="Please enter a valid text without numbers." required />
                                        </div>
                                    </div>
                                    <div className="col-lg-8">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Additional Info"
                                                name="additional_info" value={formData.additional_info} onChange={handleInput}
                                                pattern="[^0-9]*" title="Please enter a valid text without numbers." required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Skype ID"
                                                name="skypeid" value={formData.skypeid} onChange={handleInput} required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="text" class="form-control" id="exampleInputEmail1" aria-describedby=""
                                                placeholder="Twitter"
                                                name="twitter" value={formData.twitter} onChange={handleInput} required />
                                        </div>
                                    </div>
                                    <div className="col-lg-4">
                                        <div class="form-group">
                                            <input type="File" class="form-control" id="exampleInputEmail1" aria-describedby="" name="resume" onChange={handleFileChange} required />
                                        </div>
                                    </div>
                                    <div className="text-center careers-btn col-12 my-4">
                                        <button className={`team-btn ${loader ? "disable" : null}`}>SUBMIT</button>
                                        {/* <a className="team-btn">Submit</a> */}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};
export default Careers;
