import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/readyToScale"
import ClientsWords from "../../../component/ClientWord";
import StrategicPartners from "../../../component/StrategicPartners";
import {Helmet} from "react-helmet";

const SalesTeam = () => {
    return (
        <>
        <Helmet>
            <title>Sales Outsourcing | Outsourced Sdrs | B2B Sales Outsourcing For Startups in USA - StaffWiz</title>
            <meta name="description" content="Boost your sales effectiveness through sales outsourcing. Contact StaffWiz Support to leverage outsourced operations for sales success. Discover how our services can accelerate your sales growth. Get in touch today!" />
        </Helmet>
            <section className="home-banner lndg-banner">
                <div className="container">
                    <h1>Hire your world class Sales Team </h1>
                    <h3 className="my-4">Solutions for startups & small-medium businesses</h3>
                    <div className="row align-items-end">

                        <div className="col-lg-4 right-info">


                            <div className="row px-lg-3 main-catagiory">
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Save 70% on staffing costs</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Hire in weeks not months</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Boost productivity</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Quality & revenue guaranteed </b></h5>
                                </div>
                            </div>
                            <div className="home-rating-banner mt-4">
                                <img src="assets/images/SW Website asset13.png" alt="review stars"></img>
                                <img src="assets/images/HIPAA-COMPLIANT 1.png" alt="review stars"></img>
                                <img src="assets/images/SW Website asset15.png" alt="review stars"></img>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img
                                src="assets/images/home-banner1.png"
                                className="img-fluid" alt="instruction"
                            ></img>
                        </div>
                        <div className="col-lg-4">
                            <GetLandingForm heading={"HIRE NOW"} />

                        </div>
                    </div>
                </div>
            </section>
            <section className="who-are">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <h2 className="heading">Hire Sales experts starting from $8/hr*</h2>
                            <p className="lndng-auto">
                                Need a Sales expert … Or Two? Or Maybe a Whole Team?<br />
                                Let us show you how sales outsourcing can fill your employment needs at a fraction of the cost.<br /><br />
                                Staffwiz provides skilled outsourced sales development reps to handle lead qualification, appointment setting, and related tasks effortlessly, ensuring seamless operations and driving growth for your business.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-8 landing-hire-bar">
                            <div className="bar1">No contracts</div>
                            <div className="bar2">No obligations</div>
                            <div className="bar3">No hidden fees</div>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Lead Qualification</h4>
                            <p>
                                Streamline lead qualification and sales funnel optimization with an outsourced sales team. They collect prospect data, assess according to your criteria, and save your reps' time.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Lead Generation</h4>
                            <p>
                                Optimize sales by delegating lead capture forms, prospect research, and top-of-funnel tasks to remote sales assistants.
                            </p>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>CRM & Appointment Setting</h4>
                            <p>
                                Maintain an updated and organized CRM database. Empower your sales team to handle data entry, retrieval, and appointment scheduling.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Cold Calling</h4>
                            <p>
                                Virtual phone numbers facilitate outbound calls across time zones for remote sales teams, alongside leveraging email and social media communication channels.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
<p className="container">Fuel startup success with StaffWiz's specialized outsourced sales solutions. Our tailored services for startups redefine sales outsourcing, delivering unparalleled growth results. Join forces with one of the leading sales outsourcing firms and propel your startup to new heights of revenue and expansion.</p>
            <BenifitsOfOutsourcing title={"Sales and Lead generation"} />

            <HiringIsEasy />
<p className="container">Experience the power of sales outsourcing and enhance your B2B strategies with our expert outsourced sales and marketing solutions. From outsourced SDRs to comprehensive B2B sales outsourcing, we're here to turbocharge your revenue generation. 
Ready to revolutionize your sales approach? 
Check what our clients says:
</p>
            <ClientsWords />
            <StrategicPartners />

            {/* <HireTalent /> */}
            <ReadyToScale />
        </>
    );
};
export default SalesTeam;
