import React, { useEffect, useState } from "react"
import "./index.css"
import { PopupButton } from "react-calendly";
import { APP_CONFIG } from "../../config/config";
import { Link } from "react-router-dom";

const Navbar = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        // Code inside here runs once, after the component is mounted
        window.scrollTo(0, 0);
        window.addEventListener('scroll', handleScroll); 
    
        // Optionally, you can return a cleanup function here if needed
        return () => {
            window.addEventListener('scroll', handleScroll);
        };
      }, []);
      const handleScroll = () => {
        // Check if the scroll position is more than 100px
        if (window.scrollY > 100) {
            const scrolled = true; // Let's assume the user is logged in
    setScrolled(scrolled);
        } else {
            const scrolled = false; // Let's assume the user is logged in
            setScrolled(scrolled);
        }
      };
    return (
        <>
            <header>
                <div class="container">
                    <nav class="navbar navbar-expand-xl " style={{ padding: "10px 0px" }}>
                        <Link class="navbar-brand" to="/"><img src="assets/images/Staffwiz-logo.png" /></Link>
                        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="fa fa-bars"></span>
                        </button>

                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav ml-auto">
                                <li class="nav-item ">
                                    <a className="nav-link active" href="/">Home </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="/why-us">WHY US</a>
                                </li>
                                 <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        Services
                                    </a>
                                    <ul class="dropdown-menu mt15" aria-labelledby="navbarDropdown">
                                      <li class="dropdown1">  <a class="dropdown-item dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Call Center Service</a>
                                <div class="dropdown-menu dropdown-submenu" aria-labelledby="navbarDropdown">
                                        <Link class="dropdown-item" to="/answering-service">Answering Service</Link>
                                       <Link class="dropdown-item" to="/appointment-setting">Appointment Setting</Link>
                                        <Link class="dropdown-item" to="/collections">Collections</Link>
                                       <Link class="dropdown-item" to="/inbound-appointment-setting">Inbound Appointment Setting</Link>
                               <Link class="dropdown-item" to="/lead-generation">Lead Generation</Link>
                                       <Link class="dropdown-item" to="/order-processing">Order Processing</Link>
                                        <Link class="dropdown-item" to="/outbound">Outbound</Link>
                                       <Link class="dropdown-item" to="/sales-outsourcing">Sales Outsourcing</Link>
                               <Link class="dropdown-item" to="/surveys-market-research">Surveys Market Research</Link>
                                       <Link class="dropdown-item" to="/telemarketing">Telemarketing</Link>
                                        <Link class="dropdown-item" to="/third-party-verification">Third-party Verification</Link>
                                       <Link class="dropdown-item" to="/virtual-receptionist">Virtual Receptionist</Link>
                                    </div>
                            </li>
                            <li class="dropdown2">  <a class="dropdown-item dropdown-toggle" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">BPO Service</a>
                                <div class="dropdown-menu dropdown-submenu2" aria-labelledby="navbarDropdown">
                                        <a class="dropdown-item" href="/healthcare-service">Healthcare Services</a>
                                        <a class="dropdown-item" href="/fintech-service">Fintech Services</a>
                                        <a class="dropdown-item" href="/ecommerce-service">Ecommerce Services</a>
                                        <a class="dropdown-item" href="/retail-service">Retail Services</a>
                                        <a class="dropdown-item" href="/travel-support-service">Travel Support Services</a>
                                        <a class="dropdown-item" href="/insurance-service">Insurance Services</a>
                                        <a class="dropdown-item" href="/real-state-service">Real State Services</a>
                                        {/* <a class="dropdown-item" href="/travel-services">Travel Services</a> */}
                                        
                                    </div>
                            </li>
                                    </ul>
                                </li> 
                       
                                <li class="nav-item">
                                    <a class="nav-link " href="/industries">INDUSTRIES</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link " href="/on-shore">ONSHORE</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link " href="/get-started">GET STARTED</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link " href="/careers">CAREERS</a>
                                </li>
                                

                                <li class="nav-item">
                                  <a href="/schedule-call" class="nav-link back-call-btn" style={{ padding: "15px 25px",marginLeft:"50px" }}>Book a demo</a> 
                         
                                </li>
                            </ul>

                        </div>
                    </nav>
                    <div class={scrolled ? 'dark-head show-on-mobile mblnumber' : 'show-on-mobile mblnumber'} style={{padding:"15px",background:'#fff',height:'60px'}}>
    <div class="showmblPhone"  style={{float:"left"}}>
    <img class="jc-decoration-image " style={{width:"32px"}} src="assets/images/sphn.png" alt="" />
    <a href="tel: +18889994311" style={{color:"#000"}}> 1888-999-4311</a>
  </div>
    <div class="showmblwtsp"  style={{float:"right"}}>
    <img class="jc-decoration-image " src="assets/images/swhatsapp.png" alt="" style={{marginRight:'5px'}} />
    <a href="https://wa.me/8669182269"  style={{color:"#000"}}>8669182269</a>
  </div>
  </div>
                </div>

            </header>
        </>
    )
}

export default Navbar;