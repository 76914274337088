import React from "react";
import "./index.css";
import GetStartForm from "../../component/getStartForm"
import HireTalent from "../../component/hireTalent"
import {Helmet} from "react-helmet";

const OnShore = () => {
    return (
        <>
        <Helmet>
            <title>Onshore Outsourcing | domestic outsourcing in USA - StaffWiz</title>
            <meta name="description" content="Save the same amount as an offshore or nearshore outsourcing solution with StaffWiz workers based here in the US! " />
        </Helmet>
            <section className="home-banner innerHome-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="row align-items-center">
                                <div className="col-lg-7 right-info">
                                    <h1 className="heading text-left">
                                        We will connect global companies with the best onshore talent.
                                    </h1>
                                    <img
                                        className="img-fluid"
                                        src="assets/images/ask 1 1.png"
                                    ></img>
                                </div>
                                <div className="col-lg-5">
                                    <GetStartForm />

                                    <div className="row px-lg-3 main-catagiory">
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Sales</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Human Resources</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Support</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Marketing</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Accounting</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Operation</b></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section class="decided-provided">
                <div class="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            {/* <h1 className="heading">You Decide We Provide.</h1> */}
                            <div class="row">
                                <div class="col-lg-3 col-container">
                                    <div class="card">
                                        <div class="card-body">
                                            <a href="/permanent-placement">
                                                <h4 class="main-heading">Permanent Placement</h4>
                                                <p>From early-career professionals to executives.</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-container">
                                    <div class="card">
                                        <div class="card-body">
                                            <a href="/contract-talent-solutions">
                                                <h4 class="main-heading">Contract Talent Solutions</h4>
                                                <p>Hire for one day, one week or as long as you need.</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-container">
                                    <div class="card">
                                        <div class="card-body">
                                            <a href="/remote-hiring">
                                                <h4 class="main-heading">Remote Hiring</h4>
                                                <p>Seamless remote setup for millions of candidates.</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-container">
                                    <div class="card">
                                        <div class="card-body">
                                            <a href="/managed-solutions">
                                                <h4 class="main-heading">Managed Solutions</h4>
                                                <p>Find the right solution for your complex business challenges.</p>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="how-help">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <h1 className="heading">How we can help?</h1>
                            <div className="row">
                                <div className="col-lg-6">
                                    <p>We specialize in providing remote  contract and permanent talent in a variety of fields, including finance and accounting, administrative and customer support, legal, technology, and marketing and creative roles. Whether you need to fill a single position or build a project team, our recruitment specialists can swiftly match you with the right talent.</p>
                                    <p>At Staffwiz, we recognize the significance of your time and are committed to delivering efficient results. Our mission is to empower you in cultivating a dynamic and committed workforce, pivotal for the prosperity of your business. With expertise in both onshore outsourcing and domestic outsourcing, we're here to facilitate your pursuit of the right talent that propels your company towards sustained growth. Let's collaborate to drive your business to new heights.</p>
                                </div>
                                <div className="col-lg-6">
                                    <img className="img-fluid w-100 " src="assets/images/Asset website (1) 1.png"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <HireTalent />
        </>
    );
};
export default OnShore;
