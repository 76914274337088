import axios from "axios";


export const apis = {

    GetStart: (data) => axios.post("/api/getstart", data)
        .then((res) => {
            return res

        })
        .catch((err) => {
            return err
        }),
        GetStartHome: (data) => axios.post("/api/getstarthome", data)
        .then((res) => {
            return res

        })
        .catch((err) => {
            return err
        }),
        WriteReview: (data) => axios.post("/api/writereview", data)
        .then((res) => {
            return res

        })
        .catch((err) => {
            return err
        }),
    AddCandidate: (data) => axios.post("/candidates/add_candidate", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err

        }),
 ContactUs: (data) => axios.post("/contactus/email", data)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            return err

        })
}

