import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/serviceReadyToScale"
import ClientsWords from "../../../component/ClientWord";
import ServiceWhyChoose from "../../../component/serviceWhyChoose";
import ServiceBenefits from "../../../component/serviceBenefits";
import {Helmet} from "react-helmet";

const InboundAppointmentSetting = () => {
    return (
        <>
        <Helmet>
            <title>Tele Marketing Company | Cold Calling Services | Inbound Appointment Setting - Staffwiz</title>
            <meta name="description" content="Drive results with Staffwiz, a leading tele marketing company offering expert cold calling services and inbound appointment setting to boost your business growth." />
        </Helmet>
           <section className="home-banner  careers-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6">
                                    <h1>
                                        Inbound{'\n'} Appointment{'\n'} Setting
                                    </h1>
                                    <p>Elevate sales and expand your customer base with StaffWiz, a top tele marketing company. Our expertise in inbound appointment setting is a game-changer. Partner with us to unleash the full potential and excellence in customer growth.</p>
                                    
                                </div>
                                <div className="col-lg-6 right-info">
                                    <img
                                        className="img-fluid"
                                        src="assets/images/6 307.png"
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    <section className="service-form">
    <img
                                        className="img-fluid dsgn-img1"
                                        src="assets/images/BPO Service Materials 1.png"
                                    ></img>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6 pd30">
                                    <GetLandingForm heading={"Elevate Your Business with Inbound Appointment Setting by Staffwiz"} />
                                </div>
                        
                                <div className="col-lg-6 right-inf pd30">
                                <ServiceWhyChoose title={"Inbound Appointment Setting"} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
         <img
                                        className="img-fluid dsgn-img2"
                                        src="assets/images/2 565.png"
                                    ></img>
            </section>
     <ServiceBenefits title={"inbound appointment setting"} desc={"Experience the convenience and professionalism of our virtual receptionist services at Staffwiz. Whether you need a remote receptionist or a virtual answering service, our virtual receptionist team is dedicated to managing your calls efficiently, ensuring no opportunity is missed. Elevate your business communications with Staffwiz and discover the benefits of having a virtual team that works seamlessly, no matter where you are."} />
     <div className="container realizeP">
                    <div className="row">
                    <div className="col-lg-9 mx-auto">
     <p>Elevate your sales strategy with StaffWiz, your trusted tele marketing company. Experience the impact of our expert cold calling services to generate leads and drive business growth. Contact StaffWiz today for a tailored approach that maximizes your outreach and boosts conversion rates.</p>
           </div>
   <div className="col-lg-3 mx-auto">
   </div>
           </div>
            </div>
             <ClientsWords />
           

            {/* <HireTalent /> */}
            <ReadyToScale />


        </>

    );
};
export default InboundAppointmentSetting;
