import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/serviceReadyToScale"
import ClientsWords from "../../../component/ClientWord";
import ServiceWhyChoose from "../../../component/serviceWhyChoose";
import ServiceBenefits from "../../../component/serviceBenefits";
import {Helmet} from "react-helmet";

const LeadGeneration = () => {
    return (
        <>
        <Helmet>
            <title>Lead Generation Call Centers | Lead Gen Call Center | Lead Generation Telecom - Staffwiz</title>
            <meta name="description" content="Enhance your sales pipeline with expert lead generation call centers. Staffwiz offers lead gen call center solutions, lead generation telecom services, and outbound lead generation." />
        </Helmet>
           <section className="home-banner  careers-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6">
                                    <h1 className="lead">
                                        Lead Generation
                                    </h1>
                                    <p>Fuel your growth with StaffWiz! Elevate lead generation for BPOs with our innovative features and efficiency tools. As a specialized lead gen call center, we offer unparalleled telecom services tailored to boost outreach and drive substantial growth. Partner with StaffWiz for results-driven lead generation.</p>
                                    
                                </div>
                                <div className="col-lg-6 right-info">
                                    <img
                                        className="img-fluid"
                                        src="assets/images/13 1.png"
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    <section className="service-form">
    <img
                                        className="img-fluid dsgn-img1"
                                        src="assets/images/BPO Service Materials 1.png"
                                    ></img>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6 pd30">
                                    <GetLandingForm heading={"Elevate Your Business with Lead Generation by Staffwiz"} />
                                </div>
                        
                                <div className="col-lg-6 right-inf pd30">
                                <ServiceWhyChoose title={"Lead Generation"} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
         <img
                                        className="img-fluid dsgn-img2"
                                        src="assets/images/2 565.png"
                                    ></img>
            </section>
     <ServiceBenefits title={"lead generation"} />
     <div className="container realizeP">
                    <div className="row">
                    <div className="col-lg-9 mx-auto">
     <p>Elevate your business's growth with Staffwiz, a premier lead generation call center. Our lead gen call center services, driven by innovative features and a data-centric approach, ensure your BPO company achieves unparalleled success. Specializing in lead generation telecom solutions and outbound strategies, Staffwiz is your dedicated partner for efficient and results-driven lead generation. Contact us to experience a tailored approach that maximizes your outreach and drives business success.</p>
           </div>
   <div className="col-lg-3 mx-auto">
   </div>
           </div>
            </div>
             <ClientsWords />
           

            {/* <HireTalent /> */}
            <ReadyToScale />


        </>

    );
};
export default LeadGeneration;
