import React, { Component } from "react";
import "./index.css";
import GetContactForm from "../../component/getContactForm"
import Recaptcha from "react-google-recaptcha";
import { PopupWidget } from "react-calendly";
import { Contactus } from "../../services/Api";
import {Helmet} from "react-helmet";

const contactus = () => {

    return (
      <>
      <Helmet>
            <title>Contact Us | StaffWiz</title>
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col-lg-1"></div>
            <div className="col-lg-5 col-md-6">
              <div className="contact-us-form">
                <h1>Let's Talk</h1>
                <p>
                  Our Team of Friendly Experts can assist you with any Questions
                  you have or a free consultation to discuss how we can help you
                  setup an offshore team.
                </p>
                <GetContactForm heading={"HIRE NOW"} />
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="contact-us-img">
                <img src="assets/images/contact-img1.png" alt="" />
              </div>
            </div>
          </div>
          
          
        </div>

      </>
    );
  
}
export default contactus;