import React, { useState, useEffect } from "react";
import "./index.css";

import { useLocation } from 'react-router-dom';
import GetStartForm from "../../component/getStartForm"
import HireTalent from "../../component/hireTalent"
import { PopupButton } from "react-calendly";
import { APP_CONFIG } from "../../config/config";

const PermanentPlacement = () => {
    const location = useLocation();
    const [data, setData] = useState({})

    const [listData, setListData] = useState({
        "/contract-talent-solutions": {
            mainHeading: "Contract Talent Solutions",
            subHeading: "Hire an on-demand temporary employee.",
            bannerImage: "16 1 (1).png",
            infoHeading: "Access talented professionals as needed to handle fluctuating workloads, initiate new projects, or fill in for absent employees.",
            listContent: [
                "Acquire the expertise of highly skilled professionals for either short or long-term projects, whether on-site or remotely, through our contract talent program.",
                "When consistency is crucial for recurring or long-term projects, tap into our team of specialized full-time engagement professionals."
            ]
        }, "/managed-solutions": {
            mainHeading: "Managed Solutions",
            subHeading: "",
            bannerImage: "MS1 1 (1).png",
            infoHeading: "We combine Productivity's consulting services with Staffwiz's highly skilled professionals to tackle your important projects seamlessly.",
            listContent: []
        }, "/permanent-placement": {
            mainHeading: "Permanent Placement",
            subHeading: "Growing your team can be a quick and effortless process.",
            bannerImage: "ask 1 1.png",
            infoHeading: "Adding to your team can be fast and easy.",
            listContent: [
                "Our team of local talent specialists takes care of the entire hiring process on your behalf, from promoting your job and company to negotiating pay and extending offers.",
                "Utilize our Staffwiz Find A Job platform for online hiring. You have complete control over who you invite to interview for your role, and can stay in direct contact with candidates throughout the interview process until they are hired.",
                "For executive-level positions such as directors, VPs, C-suite executives, or board members, we offer a retained search service."
            ]

        }, "/remote-hiring": {
            mainHeading: "Remote Hiring",
            subHeading: "Hire locally or hire globally.",
            bannerImage: "RH 1 1 (1).png",
            infoHeading: "Talented individuals with expertise are readily available for immediate hiring, with provisions for remote and secure work arrangements.",
            listContent: [
                "Our white-glove service provides you with the necessary equipment and support for remote work, such as secure devices for candidates and virtual workspaces, making the setup process easy and hassle-free.",
                "Expand your talent search beyond geographic limitations and tap into our pool of skilled professionals, regardless of their location.",
                "Allow us to assist you in the hiring process, and get your remote workforce up and running quickly.",
                "Rest assured that your applications and data are safeguarded with our secure virtual workspaces."
            ]

        }

    })

    useEffect(() => {
        let data = listData[location.pathname]
        setData(data)
    }, [])
    return (
        <>
            <section className="home-banner innerHome-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="row align-items-center">
                                <div className="col-lg-6 right-info">
                                    <h1 className="heading-lg">
                                        {data?.mainHeading}
                                    </h1>
                                    <h5 className="sub-info">{data?.subHeading}</h5>
                                    <img
                                        className="img-fluid mt-0 img-50"
                                        src={`assets/images/${data?.bannerImage}`}
                                    ></img>
                                </div>
                                <div className="offset-lg-1 col-lg-5">
                                    <GetStartForm />
                                    <div className="row px-lg-3 main-catagiory">
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Sales</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Human Resources</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Support</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Marketing</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Accounting</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Operation</b></h5>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="services-offer">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="row">
                                <div className="col-lg-5 order-2 order-lg-1">
                                    <div className="info-area">
                                        <h5>{data?.infoHeading}</h5>
                                        <ul className="my-more">
                                            {data?.listContent?.map((data, index) => {
                                                return (
                                                    <li key={index}>{data}</li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className=" offset-lg-1 col-lg-6 order-1 order-lg-2">
                                    <h1 className="sub-heading">
                                        SERVICE WE OFFER
                                    </h1>
                                    <ul>
                                        <li>Remote Hiring</li>
                                        <li>Contract Talent Solutions</li>
                                        <li>Permanent Placement</li>
                                        <li>Managed Solutions</li>
                                    </ul>
                                    <PopupButton
                                        url={APP_CONFIG.CALENDELY.url}
                                        rootElement={document.getElementById("root")}
                                        text="Free Consultation"
                                        className="team-btn mt-4"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="industries skilled-professional">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <h1 className="heading">We can connect skilled professionals with various job openings.</h1>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/healthcare-service">
                                            <img src="assets/images/industry (1).png"></img>
                                            <p>Healthcare BPO Services</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/professional-service">
                                            <img src="assets/images/industry (4).png"></img>
                                            <p>Professional Service</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/contact-center">
                                            <img src="assets/images/industry (5).png"></img>
                                            <p>Contact Center</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/financial-services">
                                            <img src="assets/images/industry (6).png"></img>
                                            <p>Finance Service</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/admin-and-data-processing">
                                            <img src="assets/images/industry (7).png"></img>
                                            <p>Admin & data processing</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/creative-service">
                                            <img src="assets/images/industry (8).png"></img>
                                            <p>Creative Service</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/tech-support">
                                            <img src="assets/images/industry (2).png"></img>
                                            <p>Tech Support</p>
                                        </a>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="info-box">
                                        <a href="/travel-services">
                                            <img src="assets/images/industry (3).png"></img>
                                            <p>Travel BPO Services</p>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="client-word">
                <div class="container">
                    <h1 className="heading">Clients Words</h1>
                    <div id="customers-testimonials" class="owl-carousel">
                        <div class="item">
                            <div class="card">
                                <div class="card-body">
                                    <img class="img-fluid" src="assets/images/rating.svg" />
                                    <h5>Amelie Peterson - TMC inc</h5>
                                    <p>hesitantly hired a VA ( virtual assistant) for my E commerce business through Staffwiz in the Philippines, I was skeptical that an offshore staff would not be comparable to an American employee, but I have to say my VA proved me wrong she is simply phenomenal.</p>
                                </div>
                            </div>
                        </div>

                        <div class="item">
                            <div class="card">
                                <div class="card-body">
                                    <img class="img-fluid" src="assets/images/rating.svg" />
                                    <h5>D Bhatta - Bhatta Law llc</h5>
                                    <p>My Law firm gets a lot of inbound calls concerning immigration matters, we needed a couple of customer support reps to assist us with this. staffwiz found a great team for us we are very happy with them.</p>
                                </div>
                            </div>
                        </div>
                        <div class="item">
                            <div class="card">
                                <div class="card-body">
                                    <img class="img-fluid" src="assets/images/rating.svg" />
                                    <h5>Pravez Gondal - Gondal LLC</h5>
                                    <p>Staffwiz helped me find medical billing staff for my Medical staffing business at a great rate, they really match you with a team that has the right skillset youneed for your business. I hope to hire more staff in the next 3 months.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <HireTalent />
        </>
    );
};
export default PermanentPlacement;
