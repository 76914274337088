import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/readyToScale"
import ClientsWords from "../../../component/ClientWord";
import StrategicPartners from "../../../component/StrategicPartners";
import {Helmet} from "react-helmet";

const VirtualAssistant = () => {
    return (
        <>
        <Helmet>
            <title>Outsource Consultant | Outsource Virtual Assistant In USA - StaffWiz</title>
            <meta name="description" content="Looking for an outsource call center? We'll match you with the perfect partner and help you save up to 70% Get started with a free cost proposal." />
        </Helmet>
            <section className="home-banner lndg-banner">
                <div className="container">
                    <h1>Hire your world class Virtual Assistant  </h1>
                    <h3 className="my-4">Solutions for startups & small-medium businesses</h3>
                    <div className="row align-items-end">

                        <div className="col-lg-4 right-info">


                            <div className="row px-lg-3 main-catagiory">
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Save 70% on staffing costs</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Hire in weeks not months</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Boost productivity</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Quality & revenue guaranteed </b></h5>
                                </div>
                            </div>
                            <div className="home-rating-banner mt-4">
                                <img src="assets/images/SW Website asset13.png" alt="review stars"></img>
                                <img src="assets/images/HIPAA-COMPLIANT 1.png" alt="review stars"></img>
                                <img src="assets/images/SW Website asset15.png" alt="review stars"></img>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img
                                src="assets/images/home-banner1.png"
                                className="img-fluid" alt="instruction"
                            ></img>
                        </div>
                        <div className="col-lg-4">
                            <GetLandingForm heading={"HIRE NOW"} />
                        </div>
                    </div>
                </div>
            </section>
            {/* details and services */}
            <section className="who-are">
                <div className="container">
                    <h2 className="heading text-center w-100 mb-0">Hire Virtual Assistant starting from $6/hr*</h2>
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="lndng-auto">
                                <p className="text-center mt-0 mb-1">Need a Virtual Assistant expert … Or Two? Or Maybe a Whole Team?</p>
                                <p className="text-center mt-0">Let us show you how outsourcing virtual assistant services can fill your employment needs at a fraction of the cost.</p>
                                <p className="text-center mt-0">Businesses and entrepreneurs can access a diverse array of online services provided by a virtual assistant (VA) stationed at a distant location.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-8 landing-hire-bar">
                            <div className="bar1">No contracts</div>
                            <div className="bar2">No obligations</div>
                            <div className="bar3">No hidden fees</div>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Accounting & Finance</h4>
                            <p>
                                A virtual assistant specializing in accounting and finance is a digital professional that provides remote support and expertise in financial management and bookkeeping tasks.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Digital Marketing</h4>
                            <p>
                                Help businesses achieve marketing goals through online channels. Create strategies, manage social media, analyze data, and optimize campaigns to drive growth and increase brand visibility.
                            </p>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Customer Service</h4>
                            <p>
                                A virtual assistant for customer service refers to a professional who can handle email, phone, chat & text customer inquiries, support, and other related tasks remotely.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>IT Management & Support</h4>
                            <p>
                                An outsourced IT virtual assistant is a remote professional who provides admin and tech support to businesses and individuals in the IT field.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
<p className="container" >Looking to streamline your business? Outsource consultant services and virtual assistants are just a form away. Fill it up and let us provide tailored solutions for your needs.</p>
            <BenifitsOfOutsourcing title={"Virtual Assistant"} />

            <HiringIsEasy />
<p className="container" >Delegate tasks seamlessly with our outsource virtual assistant and outsource personal assistant services. Let us assist you in finding the perfect solution for your productivity needs.</p>
            <ClientsWords />
            <StrategicPartners />

            {/* <HireTalent /> */}
            <ReadyToScale />
        </>
    );
};
export default VirtualAssistant;
