import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/readyToScale"
import ClientsWords from "../../../component/ClientWord";
import StrategicPartners from "../../../component/StrategicPartners";
import {Helmet} from "react-helmet";

const MedicalSupport = () => {
    return (
        <>
        <Helmet>
            <title>Medical Billing & Coding Outsourcing | Medical Call Center Outsourcing In USA - StaffWiz</title>
            <meta name="description" content="Outsource your medical billing and coding services to us & save up to 70% of your billing cost. Call Us Now!" />
        </Helmet>
            <section className="home-banner lndg-banner">
                <div className="container">
                    <h1>Hire your world class Medical Support Team </h1>
                    <h3 className="my-4">Solutions for startups & small-medium businesses</h3>
                    <div className="row align-items-end">

                        <div className="col-lg-4 right-info">


                            <div className="row px-lg-3 main-catagiory">
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Save 70% on staffing costs</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Hire in weeks not months</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Boost productivity</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Quality & revenue guaranteed </b></h5>
                                </div>
                            </div>
                            <div className="home-rating-banner mt-4">
                                <img src="assets/images/SW Website asset13.png" alt="review stars"></img>
                                <img src="assets/images/HIPAA-COMPLIANT 1.png" alt="review stars"></img>
                                <img src="assets/images/SW Website asset15.png" alt="review stars"></img>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img
                                src="assets/images/home-banner1.png"
                                className="img-fluid" alt="instruction"
                            ></img>
                        </div>
                        <div className="col-lg-4">
                            <GetLandingForm heading={"HIRE NOW"} />
                        </div>
                    </div>
                </div>
            </section>
            {/* details and services */}
            <section className="who-are">
                <div className="container">
                    <h2 className="heading text-center w-100">Hire Medical Support experts starting from $8/hr*</h2>
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <p className="lndng-auto">Need a Medical Support expert … Or Two? Or Maybe a Whole Team?</p>
                            <p className="lndng-auto">Let us show you how healthcare outsourcing can fill your employment needs at a fraction of the cost.</p>
                            <p className="lndng-auto">Enhance healthcare delivery and achieve patient-centric excellence by strategically outsourcing staff, enabling your company to succeed.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-8 landing-hire-bar">
                            <div className="bar1">No contracts</div>
                            <div className="bar2">No obligations</div>
                            <div className="bar3">No hidden fees</div>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Medical billing</h4>
                            <p>
                                Outsourcing medical billing can help healthcare providers reduce their administrative workload and focus on providing quality care to their patients.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Medical transcription</h4>
                            <p>
                                Outsourcing medical transcription can save costs, improve accuracy and reliability, reduce infrastructure expenditures and human resources responsibility for hiring and supervising.
                            </p>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Telehealth support</h4>
                            <p>
                                Outsourcing healthcare support approach is often employed to streamline operations, reduce costs, improve efficiency, and focus on core competencies.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Claims processing</h4>
                            <p>
                                Outsourcing claim processing has the main benefit of leveraging the expertise and efficiency of specialized claim processors.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
<p className="container" >Transform your healthcare processes with comprehensive solutions: medical billing and coding outsourcing, powered by BPO healthcare expertise. Take a step towards operational excellence by completing the form. Let us provide you with a customized strategy that enhances efficiency and delivers superior results.</p>
            <BenifitsOfOutsourcing title={"Medical Support"} />

            <HiringIsEasy />
<p className="container" >Elevate your healthcare services through strategic healthcare outsourcing. Share your details and let us craft the perfect solution, whether it's medical call center outsourcing or comprehensive healthcare support. Together, we'll optimize your operations for excellence.</p>
            <ClientsWords />
            <StrategicPartners />

            {/* <HireTalent /> */}
            <ReadyToScale />


        </>

    );
};
export default MedicalSupport;
