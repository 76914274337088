
const StrategicPartners = (props) => {

    return (
        <section className={"strategic-partners " + props.className}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 mx-auto">
                        <h1 className="heading ">Strategic Partners</h1>
                        <div className="row align-items-center">
                            <div className="col-lg-3 col-6">
                                <img className="img-fluid" src="assets/images/patren (10).png" alt="partner logo"></img>
                            </div>
                            <div className="col-lg-3 col-6">
                                <img className="img-fluid" src="assets/images/partner (12).png" alt="partner logo"></img>
                            </div>
                            <div className="col-lg-3 col-6">
                                <img className="img-fluid" src="assets/images/patren (9).png" alt="partner logo"></img>
                            </div>
                            <div className="col-lg-3 col-6">
                                <img className="img-fluid" src="assets/images/patren (8).png" alt="partner logo"></img>
                            </div>

                            <div className="col-lg-3 col-6">
                                <img className="img-fluid" src="assets/images/patren (7).png" alt="partner logo"></img>
                            </div>
                            <div className="col-lg-3 col-6">
                                <img className="img-fluid" src="assets/images/partner (11).png" alt="partner logo"></img>
                            </div>

                            <div className="col-lg-3 col-6">
                                <img className="img-fluid" src="assets/images/patren (6).png" alt="partner logo"></img>
                            </div>
                            <div className="col-lg-3 col-6">
                                <img className="img-fluid" src="assets/images/patren (5).png" alt="partner logo"></img>
                            </div>
                            <div className="col-lg-3 col-6">
                                <img className="img-fluid" src="assets/images/patren (4).png" alt="partner logo"></img>
                            </div>
                            <div className="col-lg-3 col-6">
                                <img className="img-fluid" src="assets/images/patren (3).png" alt="partner logo"></img>
                            </div>
                            <div className="col-lg-3 col-6">
                                <img className="img-fluid" src="assets/images/patren (2).png" alt="partner logo"></img>
                            </div>
                            <div className="col-lg-3 col-6">
                                <img className="img-fluid" src="assets/images/patren (1).png"></img>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

}

export default StrategicPartners;