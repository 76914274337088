import React from "react";
//import GetStartForm from "./getStartForm"

const ServiceWhyChoose = (props) => {
    return (
            <>
       <h2>Why Choose Staffwiz for BPO {props?.title}?</h2>
                           <p><b>24/7 Availability:</b> With Staffwiz, you don't have to worry about missing calls or inquiries during off-hours. Staffwiz works around the clock to ensure your customers receive assistance when they need it.</p>
                                <p><b>Scalability:</b> Whether you're a startup or a large enterprise, Staffwiz can adapt to your business's changing needs. They offer flexible solutions that can be scaled up or down as required.</p>
            <p><b>Cost-Effective:</b> Outsourcing your call handling tasks to Staffwiz can significantly reduce operational costs associated with hiring and training in-house staff. You only pay for the services you need.</p>
            <p><b>Industry Expertise:</b> Staffwiz's team possesses industry-specific knowledge and can provide tailored solutions that align with your business goals and customer expectations.</p>
           <p><b>Technology Integration:</b> They employ cutting-edge technology to seamlessly integrate with your existing systems, ensuring a smooth and efficient process.</p>
         <p><b>Data Security:</b> Staffwiz prioritizes data security and complies with industry standards to protect your customers' sensitive information.</p>
           </>
    )

}
export default ServiceWhyChoose;