export const APP_CONFIG = {
    CALENDELY: {
        url: "https://api.leadconnectorhq.com/widget/appointment/service/discovery?group=staffwiz",
        utm: {
            utmCampaign: "Job Advertisement 2020",
            utmSource: "Facebook",
            utmMedium: "Ad",
            utmContent: "Jobs",
            utmTerm: "Spring",
        },
        pageSettings: {
            backgroundColor: "ffffff",
            hideEventTypeDetails: false,
            hideLandingPageDetails: false,
            primaryColor: "00a2ff",
            textColor: "4d5055",
        }
    }
}