import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/serviceReadyToScale"
import ClientsWords from "../../../component/ClientWord";
import ServiceWhyChoose from "../../../component/serviceWhyChoose";
import ServiceBenefits from "../../../component/serviceBenefits";
import {Helmet} from "react-helmet";

const SurveysMarketResearch = () => {
    return (
        <>
        <Helmet>
            <title>Market Research Companies | Paid Market Research | Market Research Agency - Staffwiz</title>
            <meta name="description" content="Partner with leading market research companies for accurate insights. Staffwiz offers paid market research services, market research survey expertise, and reliable market research agency support." />
        </Helmet>
           <section className="home-banner  careers-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6">
                                    <h1 className="order">
                                        Surveys & Market{'\n'} Research
                                    </h1>
                                    <p>StaffWiz empowers organizations with efficient insight gathering, effective data analysis, and informed decision-making for success. As a leading market research firm, we specialize in services like paid research and surveys. Trust StaffWiz for all strategic market research needs, from enhancing customer satisfaction to identifying trends and developing new products.</p>
                                    
                                </div>
                                <div className="col-lg-6 right-info">
                                    <img
                                        className="img-fluid"
                                        src="assets/images/10 1 (1).png"
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    <section className="service-form">
    <img
                                        className="img-fluid dsgn-img1"
                                        src="assets/images/BPO Service Materials 1.png"
                                    ></img>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6 pd30">
                                    <GetLandingForm heading={"Elevate Your Business with Surveys & Market Research by Staffwiz"} />
                                </div>
                        
                                <div className="col-lg-6 right-inf pd30">
                                <ServiceWhyChoose title={"Surveys & Market Research"} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
         <img
                                        className="img-fluid dsgn-img2"
                                        src="assets/images/2 565.png"
                                    ></img>
            </section>
     <ServiceBenefits title={"surveys & market research"} />
     <div className="container realizeP">
                    <div className="row">
                    <div className="col-lg-9 mx-auto">
     <p>Unlock strategic insights with StaffWiz, your premier choice among market research companies. Elevate your decision-making with our expert services, including paid market research and tailored market research surveys. Contact StaffWiz today - your trusted market research agency - and transform data into actionable intelligence for business success.</p>
           </div>
   <div className="col-lg-3 mx-auto">
   </div>
           </div>
            </div>
             <ClientsWords />
           

            {/* <HireTalent /> */}
            <ReadyToScale />


        </>

    );
};
export default SurveysMarketResearch;
