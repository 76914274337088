import React, { useState } from "react";
import "./index.css";
import GetStartForm from "../../component/getStartForm"
import HireTalent from "../../component/hireTalent"
import {Helmet} from "react-helmet";

const JobRoles = () => {
    const [list, setList] = useState([
        {
            mainHeading: "Healthcare service",
            detail: "Exploring medical billing outsourcing companies? Offshoring healthcare services presents a cost-effective alternative to on-site recruitment, offering adaptable solutions to your evolving business requirements. Embracing offshore staff through outsourcing enhances productivity, streamlines patient treatment times, and curbs expenses, all while maintaining exceptional quality standards.",
            image: "Untitled design (2) 1.png",
            listContent: [
                "Medical Encoding",
                "Nurses / Remote Patient Monitoring",
                "Clinical Abstracting",
                "Medical transcriptionist",
                "Medical Billing",
                "Clinic Admin/ Scheduling",
                "Medical Claims Administration",
                "Insurance / Billing / Vaccine",
                "Telephone Triage Nurse",
                "Scheduling / Claims / Verification"
            ]
        }, {
            mainHeading: "Professional services",
            detail: "We can find an extensive pool of highly skilled offshore professionals for your business as long as the tasks can be performed remotely. Your professional services firm's success is dependent on the quality of your workforce, and we can assist you in sourcing the best talent from the Philippines for various areas such as Legal Process Outsourcing (LPO), Back Office support, and Knowledge Process Outsourcing (KPO).",
            image: "SW Website asset (8) 1.png",
            listContent: [
                "Project Management",
                "Performance Management",
                "Operations Management",
                "Consulting",
                "Legal",
                "Business Process Analysis",
                "Recruitment",
                "Logistics Support",
                "Human Resources (HR)",
                "Paralegal"
            ]
        }, {
            mainHeading: "Contact center",
            detail: "Introducing Staffwiz, your premier offshore call center solution. We specialize in a wide range of customer contact services, encompassing inbound and outbound voice calls, as well as data applications such as e-mail, social media, and instant messaging. As a leader in the field, Staffwiz capitalizes on the Philippines' top-ranking status for offshore contact center services. From customer acquisition to technical support, our expertise covers it all - retention, service, cross-selling, up-selling, and credit management services. Partner with Staffwiz for seamless offshore customer engagement.",
            image: "SW Website asset (10) 1.png",
            listContent: [
                "Lead Generation",
                "Customer Acquisition",
                "Customer Service",
                "Customer Care",
                "Technical Support",
                "Reservation Agent",
                "Collections",
                "E-Commerce Support",
                "Data Cleansing & Validation",
                "Account Management"
            ]
        }, {
            mainHeading: "Financial services",
            detail: "At Staffwiz, we possess extensive expertise in establishing offshore finance teams that offer Accounting, Financial, Insurance, and Superannuation services to our clients. Our pool of highly qualified professionals in the Philippines ranges from Accountants and Bookkeepers to Financial planners and Auditors. Additionally, we have the know-how to assemble teams of debt collectors.",
            image: "SW Website asset (12) 1.png",
            listContent: [
                "Claims Processing",
                "Accounts Receivable",
                "Debt Collection",
                "Payroll Processing",
                "Financial Analysis",
                "Accounting",
                "Debt Collection",
                "Bookkeeping",
                "Financial Advice"
            ]
        }, {
            mainHeading: "Admin & data processing",
            detail: "Our team has expertise in recruiting full-time administrative assistants who are dedicated to supporting your needs in data entry, data processing, transcription, or secretarial tasks. We offer a wide range of back-office services, including email and website support, data entry and processing, and other administrative tasks. Your offshore administrative team can help with procurement, social media community moderation, and more.",
            image: "SW Website asset (13) 1.png",
            listContent: [
                "Administrative Assistant",
                "Website Support",
                "Data Entry",
                "Procurement",
                "Data Processing",
                "Administrative Support",
                "Virtual Assistance",
                "Real Estate Support",
                "Personal Assistance",
                "E-Commerce Admin"
            ]
        }, {
            mainHeading: "Creative service",
            detail: "The Philippines has emerged as a prominent choice for offshoring creative services, and Staffwiz has accomplished the creation of top-notch creative teams for its global customers, encompassing diverse roles such as Web and Graphic Designers, Animators, and Industrial Designers.",
            image: "SW Website asset (14) 1.png",
            listContent: [
                "Graphic Design",
                "Storyboard Art",
                "Proofreading",
                "Animation",
                "Creative Writing",
                "3D Modeling",
                "Web Design",
                "UX Design",
                "Industrial Design",
                "Photo Editing"
            ]
        }, {
            mainHeading: "Tech Support",
            detail: "When it comes to hiring offshore developers, look no further than Staffwiz Tech Support. Our remote team comprises adept professionals offering top-notch technical support on a global scale. With extensive industry experience, our experts possess an intricate knowledge of diverse technology platforms, software applications, and systems. Trust Staffwiz for a proficient and knowledgeable offshore team that's ready to support your technological needs.",
            image: "SW Website asset (15) 1.png",
            listContent: [
                "Tier 1 Help Desk",
                "Database Administration",
                "Network Monitoring",
                "Web Dev / IT Programming"
            ]
        }, {
            mainHeading: "Travel Services",
            detail: "Staffwiz is a highly respected and reliable remote travel services agent that offers a wide range of services to its clients. As a remote travel services agent, Staffwiz allows its clients to access their services from anywhere in the world, which makes it highly convenient for people who are constantly on the move or who live in remote locations. With Staffwiz, clients can book flights, hotels, rental cars, and other travel services with ease.",
            image: "SW Website asset (16) 1.png",
            listContent: [
                "Air / Hotel / Auto",
                "Booking / sales",
                "Service / Changes",
                "Retention"
            ]
        }
    ])
    return (
        <>
        <Helmet>
            <title>Knowledge Process Outsourcing | Legal Process Outsourcing | Offshore Call Center in USA - Staffwiz</title>
            <meta name="description" content="We are a highly professional company specializing in knowledge process outsourcing, legal process outsourcing, medical billing outsourcing, offshore call center services, and development solutions. Trust our expertise to streamline your business processes and drive success." />
        </Helmet>
            <section className="home-banner innerHome-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="row">
                                <div className="col-lg-5">
                                    <GetStartForm />
                                    <div className="row px-lg-3 main-catagiory">
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Sales</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Human Resources</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Support</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Marketing</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Accounting</b></h5>
                                        </div>
                                        <div className="col-lg-6 col-6">
                                            <h5 className=""><b>Operation</b></h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-7 right-info">
                                    <h1>Acquire the most valuable talent for your business.</h1>
                                    <img className="img-fluid" src="assets/images/inner-2.png"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="job-roles">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="job-roles-header">
                                <h1 className="heading">We can build remote teams for any service you need. </h1>
                                <p className="section-disc">Staffwiz offers the benefit of having a full-time dedicated remote team who can work from either their home or office. These professionals report directly to you on a daily basis. By partnering with Staffwiz, you have the ability to expand your organization in a cost-effective manner while also having a dedicated offshore team that will seamlessly integrate with your own organization.</p>
                            </div>
                            {list?.map((data, index) => {
                                return (
                                    <div className="jobroles-inner-sec" key={index}>
                                        <div className="row">
                                            <div className="col-lg-7">
                                                <h1 className="sub-heading">{data?.mainHeading}</h1>
                                                <p>{data?.detail}</p>
                                                <div className="row">
                                                    <div className={`${data?.listContent?.length<5? 'col-lg-12' : 'col-lg-6'}`}>
                                                        <div className="servies-list ">
                                                            <ul className="pl-0">
                                                                {data?.listContent?.map((value, index) => {
                                                                    return (
                                                                        index % 2 === 0 ?
                                                                            <li>
                                                                                <span>
                                                                                    <i class="fas fa-long-arrow-alt-right"></i>
                                                                                </span>{value}
                                                                            </li>
                                                                            : null
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-6">
                                                        <div className={`servies-list ${data?.listContent?.length<5? 'mt-0' : ''}`}>
                                                            <ul className="pl-0">
                                                                {data?.listContent?.map((value, index) => {
                                                                    return (
                                                                        index % 2 !== 0 ?
                                                                            <li>
                                                                                <span>
                                                                                    <i class="fas fa-long-arrow-alt-right"></i>
                                                                                </span>{value}
                                                                            </li>
                                                                            : null
                                                                    )
                                                                })}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-lg-5">
                                                <img className="img-fluid" src={`assets/images/${data?.image}`}></img>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>

            <HireTalent />

        </>
    );
};
export default JobRoles;
