import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/readyToScale"
import ClientsWords from "../../../component/ClientWord";
import StrategicPartners from "../../../component/StrategicPartners";
import {Helmet} from "react-helmet";

const TravelAgent = () => {
    return (
        <>
        <Helmet>
            <title>Outsource Travel | Travel BPO | Travel Process Outsourcing | Outsourcing Travel Management - Staffwiz</title>
            <meta name="description" content="Outsource travel services with Staffwiz's travel BPO solutions. Experience efficient travel process outsourcing and outsourcing travel management to streamline operations." />
             </Helmet>
            <section className="home-banner lndg-banner">
                <div className="container">
                    <h1>Hire your world class Travel Agent</h1>
                    <h3 className="my-4">Solutions for startups & small-medium businesses</h3>
                    <div className="row align-items-end">

                        <div className="col-lg-4 right-info">


                            <div className="row px-lg-3 main-catagiory">
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Save 70% on staffing costs</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Hire in weeks not months</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Boost productivity</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Quality & revenue guaranteed </b></h5>
                                </div>
                            </div>
                            <div className="home-rating-banner mt-4">
                                <img src="assets/images/SW Website asset13.png" alt="review stars"></img>
                                <img src="assets/images/HIPAA-COMPLIANT 1.png" alt="review stars"></img>
                                <img src="assets/images/SW Website asset15.png" alt="review stars"></img>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img
                                src="assets/images/home-banner1.png"
                                className="img-fluid" alt="instruction"
                            ></img>
                        </div>
                        <div className="col-lg-4">
                            <GetLandingForm heading={"HIRE NOW"} />
                        </div>
                    </div>
                </div>
            </section>
            {/* details and services */}
            <section className="who-are">
                <div className="container">
                    <h2 className="heading text-center w-100 mb-0">Hire Travel Agent starting from $8/hr*</h2>
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="lndng-auto">
                                <p className="text-center mt-0 mb-1">Need a Travel Agent expert … Or Two? Or Maybe a Whole Team?</p>
                                <p className="text-center mt-0">Let us show you how outsourcing travel process services can fill your employment needs at a fraction of the cost.</p>
                                <p className="text-center mt-0">Our remote travel agent experts utilize GDS tools such as Sabre, Amadeus, Worldspan, and Galileo to connect with airlines, hotels, and car rental companies. They can search for flights, compare prices, make bookings, issue tickets, and manage reservations on behalf of our clients.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-8 landing-hire-bar">
                            <div className="bar1">No contracts</div>
                            <div className="bar2">No obligations</div>
                            <div className="bar3">No hidden fees</div>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Flights</h4>
                            <p>
                                Flight-specializing travel agents assist clients in booking air travel, leveraging their knowledge of airlines, routes, and ticketing systems. They handle inquiries, provide itineraries, and ensure travel requirements are met.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Car</h4>
                            <p>
                                Car travel agent functionality in an all-in-one platform allows users to easily search, compare, and book car rentals from various providers. It also offers personalized travel itineraries, suggesting popular destinations and optimizing routes for an enhanced travel experience.
                            </p>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Hotel</h4>
                            <p>
                                Assisting with various tasks, from managing reservations and check-ins to providing information about the hotel, ensures guest satisfaction. Whether it's booking appointments or addressing any concerns,
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Cruise</h4>
                            <p>
                                As a virtual assistant for cruise ships, assisting with tasks and inquiries and ensuring a seamless experience for guests and crew members on board.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
<p className="container" >Explore efficient solutions for your travel needs with our outsource travel and travel BPO services. Let us assist you in optimizing your travel operations.</p>
            <BenifitsOfOutsourcing title={"Travel Agent"} />

            <HiringIsEasy />
<p className="container" >Elevate your operations with the power of travel process outsourcing and the efficiency of outsourcing travel management. Take the first step by filling up the form, and let us unlock tailored solutions to revolutionize your travel experience.</p>
            <ClientsWords />
            <StrategicPartners />

            {/* <HireTalent /> */}
            <ReadyToScale />
        </>
    );
};
export default TravelAgent;
