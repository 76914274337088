import React from "react";
import "./index.css"
const Careers_Thankyou = () => {

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                        <div className="team-popup-cotent">

                            <div className="bill-heading">
                                <div className="position-relative margin-t20px text-center">
                                    <h1 className="pt-5 mb-5">
                                        Thank you for your request
                                    </h1>
                                </div>
                            </div>
                            <div className="text-center mb-5">
                                <a className="team-btn" href="/">
                                    Back to Homepage
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>
    )
}

export default Careers_Thankyou;