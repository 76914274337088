import React from "react";
import "./index.css";
import GetStartForm from "../../component/homeForm"
import HireTalent from "../../component/hireTalent"
import ClientsWords from "./ClientWord";
import StrategicPartners from "./StrategicPartners";
import { PopupButton } from "react-calendly";
import { APP_CONFIG } from "../../config/config";
import {Helmet} from "react-helmet";

const Home = () => {
    return ( 
        <>
         <Helmet>
            <title>Staffing Solution | Recruitment Solution | Outsourcing Company | Remote Staffing Agency in USA - StaffWiz</title>
            <meta name="description" content="Explore comprehensive staffing and recruitment solutions, business process outsourcing (BPO), RPO recruitment, and outsourcing services at StaffWiz. Discover placement services, remote staffing, and employer of record (EOR) solutions for seamless workforce management." />
        </Helmet>
            <section className="home-banners">
                <div className="container">
                <div className="home-banner">
                    <div className="row align-items-end">
                    
                        <div className="col-lg-7 right-info">
                            <h1>Connecting People, <br />Building Brands</h1>
                            <h3 className="my-4">Elevate your business with our <br />BPO & call center services.</h3>

                           
                            <div className="home-rating-banner mt-4">
                                <h6><img src="assets/images/starts.svg" alt="review stars"></img> <span>Reviews by clients worldwide</span></h6>
                            </div>
                            <img src="assets/images/office woman website SW 2 1.png" alt="" className="ladyImg"/>
                    </div>
                    {/* <div className="col-lg-4">
                            <img
                                src="assets/images/home-banner1.png"
                                className="img-fluid" alt="instruction"
                            ></img>
                        </div> */}
                        
                        
                        <div className="col-lg-5">
                            <span className="c1"></span>
                            <h2>Boost Your Business</h2>
                            <span className="l1"></span>
                      
                            <GetStartForm
                                heading={"READY TO GET STARTED?"} />

                        </div>
                    </div>
                    

                </div>
                <div className="green-banner">
                    <div className="hNumber">
                        <h7>In the News:</h7>
                        <img
                                src="assets/images/cnn-img1 1.png" alt="instruction"
                            ></img>
                    </div>
                    <div className="hNumber">
                    <img
                                src="assets/images/fox-img1 1.png" alt="instruction"
                            ></img>
                    </div>
                    <div className="hNumber">
                    <img
                                src="assets/images/cnn-img3 1.png" alt="instruction"
                            ></img>
                    </div>
                    </div>
                </div>
                
            </section>
            <section className="welcome-stf">
            <div className="container">
                    <div className="row">
                        <div className="col-lg-8 ">
                            <h2>Welcome to <span>StaffWiz</span></h2>
                            <p>
                            At StaffWiz, we're not just a BPO call center; we're your strategic ally for operational efficiency and workforce optimization. Committed to innovation, customer satisfaction, and expertise, we're a trusted leader in business process outsourcing.
                            </p>
                            </div>
                            <div className="col-lg-4 ">
                                <img src="assets/images/Ellipse 96.png" className="rghtImg" alt="" />
                                
                            </div>
                            <img src="assets/images/Line 15.png" className="line" alt="" />
                            
                            <div className="col-lg-7 ">
                                <img src="assets/images/Ellipse 97.png" className="rghtImg" alt="" />
                                
                            </div>
                            <div className="col-lg-5 ">
                                <h1>
                                <span>Complete Staffing</span> and Recruitment Solutions
                                </h1>
                                
                                <div className="Services">
                                <img src="assets/images/Ellipse 89.png" className="line1" alt="" />
                                <div className="subDiv">
                                    <h5>
                                    BPO Solutions:
                                    </h5>
                                    <p>Elevate customer service with our efficient and professional agents.</p>
                                    <h5>
                                    Call center Solutions:
                                    </h5>
                                    <p>Prioritize flexible scalability for seamless adaptation to changing demands.</p>
                                    <h5>
                                    Staff Augmentation:
                                    </h5>
                                    <p>Seamlessly scale your workforce with top-notch professionals.</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                            </div>
            </section>
            <section className="apart">
                <div className="container">
                    <div className="row">
                    <h3>What Sets Us Apart:</h3>
                    <div className="col-lg-4 grp">
                        <img src="assets/images/Group 116.png"  alt="" />
                        <img src="assets/images/8 1.png" className="fcImg"  alt="" />
                        <h4 className="pd1">Innovation:</h4>
                        <p>We bring cutting-edge solutions to propel your business forward.</p>
                    </div>
                    <div className="col-lg-4 grp">
                        <img src="assets/images/Group 116.png" alt="" />
                        <img src="assets/images/9 7.png" className="fcImg"  alt="" />
                        <h4 className="pd2">Customer-Centric:</h4>
                        <p>Your success is our priority; we go the extra mile for exceptional experiences.</p>
                    </div>
                    <div className="col-lg-4 grp">
                        <img src="assets/images/Group 116.png"  alt="" />
                        <img src="assets/images/10 1.png" className="fcImg" alt="" />
                        <h4 className="pd3">Expertise:</h4>
                        <p>Our seasoned professionals deliver tailored solutions based on industry insights.</p>
                    </div>
                        </div>
                        </div>
                        <div className="g-section">
                        <div className="hNumber">
                        <h4>96%</h4>
                        <p>Client Satisfaction</p>
                    </div>
                    <div className="hNumber">
                        <h4>500+</h4>
                        <p>Projects</p>
                    </div>
                    <div className="hNumber">
                        <h4>2000+</h4>
                        <p>Happy Agents!</p>
                    </div>
                        </div>
                        </section>
                        <section className="our-service">
                        <div className="container">
                    <div className="row">
                        <h4>Services</h4>
                        <div className="col-lg-2">
                            </div>
                            <div className="col-lg-8">
                            <div className="row">
                            <div className="col-lg-6">
                                <img src="assets/images/G160.png" alt="" />
                                <h5>Customer Support</h5>
                            </div>
                            <div className="col-lg-6">
                                <img src="assets/images/G160.png" alt="" />
                                <h5>Admin & data processing</h5>
                            </div>
                            <div className="col-lg-6">
                                <img src="assets/images/G160.png" alt="" />
                                <h5>Healthcare BPO Services</h5>
                            </div>
                            <div className="col-lg-6">
                                <img src="assets/images/G160.png" alt="" />
                                <h5>Finance Service</h5>
                            </div>
                            <div className="col-lg-6">
                                <img src="assets/images/G160.png" alt="" />
                                <h5>Professional Service</h5>
                            </div>
                            <div className="col-lg-6">
                                <img src="assets/images/G160.png" alt="" />
                                <h5>Tech Support</h5>
                            </div>
                            <div className="col-lg-6">
                                <img src="assets/images/G160.png" alt="" />
                                <h5>IT Staff  Augmentation</h5>
                            </div>
                            <div className="col-lg-6">
                                <img src="assets/images/G160.png" alt="" />
                                <h5>Travel BPO Services</h5>
                            </div>
                            </div>
                            </div>
                            <div className="col-lg-2">
                            </div>
                            </div>
                            
                        </div>
                        <img src="assets/images/Ellipse 90.png" alt="" className="service-img" />
                        </section>
                        <section className="why-partner">
                        <div className="container">
                    <div className="row">
                            <h2>Why Partner with <span>StaffWiz</span>  for Staffing Solutions?</h2>
                            <div className="col-lg-8">
                            <div className="row">
                            <div className="col-lg-6 ">
                                <div className="partner-text">
                            <span className="green-line"></span>
                            <h5>Strategic BPO Services:</h5>
                            <p>Enhance efficiency, cut costs, and streamline your processes with our cutting-edge BPO solutions.</p>
                            </div>
                            <div className="partner-text">
                            <span className="green-line"></span>
                            <h5>Exceptional Call Center Expertise:</h5>
                            <p>Elevate customer experience with 24/7 multichannel support and highly skilled agents.</p>
                            </div>
                            </div>
                            <div className="col-lg-6 ">
                            <div className="partner-text">
                            <span className="green-line"></span>
                            <h5>Proven Success:</h5>
                            <p>Explore our success stories and testimonials showcasing how we've transformed businesses with our holistic outsourcing solutions.</p>
                            </div>
                            <div className="partner-text pdt0">
                            <span className="green-line"></span>
                            <h5>Robust Security:</h5>
                            <p>Trust in our state-of-the-art technology infrastructure and robust security measures for peace of mind.</p>
                            </div>
                            </div>
                            <h4>Ready to scale your Business?</h4>
                            <a href="/schedule-call" >Book a demo</a>
                            </div>
                            </div>
                            <div className="col-lg-4 ">
                            
                            </div>
                            </div>
                            </div>
                            <img src="assets/images/Ellipse 99.png" alt="" className="service-img" />
                        </section>
                        <section className="how-works">
                        <div className="container">
                    <div className="row">
                        <div className="col-lg-6 mx-auto">
                            <h2><span>How It Works:</span> Simplifying Your Staffing and Recruitment Process </h2>
                            <p>Whether you’re looking to set up a small group(1-10) for your business or a large team(11-100+) for your contact center, we help guide you and assist in hiring, managing, and setting up your business process. We’re with you before, during, and after you take off.</p>
                        </div>
                        <div className="col-lg-6 mx-auto">
                        <img src="assets/images/Ellipse 95.png" alt="" className="right-img" />
                        </div>
                            </div>
                            <div className="row">
                            <div className="col-lg-1 mx-auto"></div>
                            <div className="col-lg-5 mx-auto">
                                <div className="how-works-text">
                                {/* <span className="green-line"></span> */}
                            <h5>You Select Your Team</h5>
                            <img src="assets/images/image 1.png" className="line1" alt="" />
                                <div className="subDiv">
                                    <p>Tap and assemble a team utilizing a talent pool <br />that has been pre-screened by us.</p>
                                    
                                    <p>Interview the selected candidates for <br />final approval.</p>
                                    
                                    <p>Take charge of training and <br />onboarding with our assistance.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 mx-auto">
                            <div className="how-works-text">
                                {/* <span className="green-line"></span> */}
                            <h5>We Manage Your Team</h5>
                            <img src="assets/images/image 1.png" className="line1" alt="" />
                                <div className="subDiv">
                                    <p className="pFirst">We manage team selection for you.</p>
                                    
                                    <p>We handle the training and <br />onboarding of your new team.</p>
                                    
                                    <p>Let us handle team management, QA, <br />and more while you sit back and relax.</p>
                                    </div>
                                </div>
                            </div>
                            </div>
                            </div>
                        </section>
                        <section className="top-bpo">
                        <div className="container">
                    <div className="row">
                        <h3>Elevate Your Business with Top Staffing Agency and BPO Solutions</h3>
                        <h5>Streamline Operations, Enhance Customer Experience, and Boost Efficiency</h5>
                        <div className="col-lg-5 mx-auto">
                        <img src="assets/images/Ellipse 98.png" className="ine1" alt="" />
                        </div>
                        <div className="col-lg-7 mx-auto">
                            <div className="right-div">
                            <img src="assets/images/Group 119.png" className="ine1" alt="" />
                            <span>24/7 Multichannel Support</span>
                            </div>
                            <div className="right-div">
                            <img src="assets/images/Group 119.png" className="ine1" alt="" />
                            <span>Cutting-edge Technology for <br />Seamless Interactions</span>
                            </div>
                            <div className="right-div">
                            <img src="assets/images/Group 119.png" className="ine1" alt="" />
                            <span>Customized Solutions Tailored to Your<br /> Business Needs</span>
                            </div>
                            <h4>Unlock Success Today!</h4>
                           <div className="btn-border"> <a href="/schedule-call" > Book a Call with Our Experts</a></div>
                        </div>
                            </div>
                            </div>
                            <img src="assets/images/Ellipse 94.png" className="btm-line" alt="" />
                        </section>
           
            {/* <StrategicPartners/> */}

            {/* <HireTalent /> */}

            <ClientsWords/>

        </>

    );
};
export default Home;
