import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/serviceReadyToScale"
import ClientsWords from "../../../component/ClientWord";
import ServiceWhyChoose from "../../../component/serviceWhyChoose";
import ServiceBenefits from "../../../component/serviceBenefits";
import {Helmet} from "react-helmet";

const Outbound = () => {
    return (
        <>
        <Helmet>
            <title>Outbound Call Center | Outbound Calls Call Center | Outsource Telemarketing - Staffwiz</title>
            <meta name="description" content="Boost your business with Staffwiz's outbound call center solutions. We specialize in outbound calls, telemarketing outsourcing, call center outsourcing, and outbound sales." />
        </Helmet>
           <section className="home-banner  careers-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6">
                                    <h1>
                                        Outbound
                                    </h1>
                                    <p>In the quest for a competitive edge, BPO outbound services, like outbound calls and telemarketing, stand out. StaffWiz leads the way, enabling companies to expand reach, enhance efficiency, and drive growth. Choose StaffWiz for outbound sales excellence and success in outsourcing solutions.
</p>
                                    
                                </div>
                                <div className="col-lg-6 right-info">
                                    <img
                                        className="img-fluid"
                                        src="assets/images/8 1 (1).png"
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    <section className="service-form">
    <img
                                        className="img-fluid dsgn-img1"
                                        src="assets/images/BPO Service Materials 1.png"
                                    ></img>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6 pd30">
                                    <GetLandingForm heading={"Elevate Your Business with Outbound by Staffwiz"} />
                                </div>
                        
                                <div className="col-lg-6 right-inf pd30">
                                <ServiceWhyChoose title={"Outbound"} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
         <img
                                        className="img-fluid dsgn-img2"
                                        src="assets/images/2 565.png"
                                    ></img>
            </section>
     <ServiceBenefits title={"outbound"} />
     <div className="container realizeP">
                    <div className="row">
                    <div className="col-lg-9 mx-auto">
     <p>Maximize your business potential with StaffWiz, your trusted outbound call center partner. Elevate customer engagement through expert outbound calls call center services and outsource telemarketing tailored to your needs. Explore the benefits of outbound call centre outsourcing with StaffWiz for impactful outbound sales-contact us to boost your business efficiency and drive growth.</p>
           </div>
   <div className="col-lg-3 mx-auto">
   </div>
           </div>
            </div>
             <ClientsWords />
           

            {/* <HireTalent /> */}
            <ReadyToScale />


        </>

    );
};
export default Outbound;
