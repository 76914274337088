import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/serviceReadyToScale"
import ClientsWords from "../../../component/ClientWord";
import ServiceWhyChoose from "../../../component/serviceWhyChoose";
import ServiceBenefits from "../../../component/serviceBenefits";
import {Helmet} from "react-helmet";

const SalesOutsourcing = () => {
    return (
        <>
        <Helmet>
            <title>Sales Outsourcing | Telesales Outsourcing | Sales Outsourcing Companies - Staffwiz</title>
            <meta name="description" content="Maximize your sales with expert sales outsourcing from Staffwiz. We offer telesales outsourcing, B2B sales outsourcing, and tailored solutions from top sales outsourcing companies." />
        </Helmet>
           <section className="home-banner  careers-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6">
                                    <h1 className="order">
                                        Sales Outsourcing
                                    </h1>
                                    <p>Transform your business with StaffWiz's sales outsourcing-a game-changer for expanding market reach and driving revenue growth. Backed by expertise, our telesales outsourcing ranks among top-tier services. Partner with us, your trusted outsourced sales firm, for tailored B2B solutions. Elevate your sales strategy while focusing on your core strengths.</p>
                                    
                                </div>
                                <div className="col-lg-6 right-info">
                                    <img
                                        className="img-fluid"
                                        src="assets/images/9 1 (1).png"
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    <section className="service-form">
    <img
                                        className="img-fluid dsgn-img1"
                                        src="assets/images/BPO Service Materials 1.png"
                                    ></img>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6 pd30">
                                    <GetLandingForm heading={"Elevate Your Business with Sales Outsourcing by Staffwiz"} />
                                </div>
                        
                                <div className="col-lg-6 right-inf pd30">
                                <ServiceWhyChoose title={"Sales Outsourcing"} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
         <img
                                        className="img-fluid dsgn-img2"
                                        src="assets/images/2 565.png"
                                    ></img>
            </section>
     <ServiceBenefits title={"sales outsourcing"} />
     <div className="container realizeP">
                    <div className="row">
                    <div className="col-lg-9 mx-auto">
     <p>Supercharge your business growth with StaffWiz - the go-to choice for sales outsourcing. Elevate your strategy through top-notch telesales outsourcing, trusted by leading sales outsourcing companies. Partner with our outsourced sales firm for tailored B2B sales outsourcing and outsourced sales and marketing solutions. Contact StaffWiz today and unlock a new era of sales success.</p>
           </div>
   <div className="col-lg-3 mx-auto">
   </div>
           </div>
            </div>
             <ClientsWords />
           

            {/* <HireTalent /> */}
            <ReadyToScale />


        </>

    );
};
export default SalesOutsourcing;
