import React, { useState } from "react";
import { apis } from "../services/Api"
import { useNavigate } from "react-router-dom";

const HomeForm = (props) => {
    const [inputValue, setInputValue] = useState({});
    const [loader, setLoader] = useState(false)
    const [form1, setForm1] = useState(true)
    const [form2, setForm2] = useState(false)
    const [emal, setEmal] = useState(false)
    const [thankForm, setThankForm] = useState(false)
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        setInputValue({
            ...inputValue,
            [event.target.name]: event.target.value
        });
    }
    const handleForm = async (e) => {
        e.preventDefault();
        const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
        if(inputValue?.email && inputValue?.email.length && inputValue?.email.match(isValidEmail)){
            setForm1(false)
            setForm2(true)
            setEmal(false)
          }else{
            setEmal(true)
          }
        
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true)
        
        console.log("inputValue", inputValue)
        const data = {
            name: inputValue?.name,
            email: inputValue?.email,
            phone: inputValue?.phone,
            comment: inputValue?.comment,
        };
        if (Object.values(data).every(value => value !== undefined)) {
            const res = await apis.GetStartHome(data);
            if (res) {
                setLoader(false)
                setThankForm(true)
        setForm1(false)
        setForm2(false)
                // console.log("res", res)
                window.scroll({ top: 0, behavior: "smooth" });
               // navigate('/get-started-Thankyou');
            }
        }
    };
    return (
        <>
              <div className="banner-from">
              {form1 &&  <form onSubmit={e => handleForm(e)}>
              <div className="card">
                    <h2>Talk to Our Team</h2>
                    <div className="formField">
                    <p>Business Email*</p>
                    <input
                                            type="text"
                                            class="textField"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Name@email.com"
                                            name="email"
                                            value={inputValue?.email}
                                            onChange={handleInputChange}
                                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                                            title="Please enter a valid email address."
                                            required
                                        />
                                     {emal &&   <span>Please enter a valid email</span> }
                    </div>
                    <div className="bGray">
                    <button className="team-btns" >Submit</button>
                    </div>
                    </div>
                    </form>
}
{form2 && <form onSubmit={e => handleSubmit(e)}>
     <div className="form2 ">
                    <div className="row">
                    <div className="col-lg-6">
                    <p>First Name*</p>
                    <input
                                            type="text"
                                            class="textField"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Name"
                                            name="name"
                                            value={inputValue?.name}
                                            onChange={handleInputChange}
                                            pattern="[^0-9]*"
                                            title="Please enter a valid text without numbers."
                                            required
                                        />
                    
                    </div>
                    <div className="col-lg-6">
                    <p>Phone Number*</p>
                    <input
                                            type="text"
                                            class="textField"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Phone number"
                                            name="phone"
                                            value={inputValue?.phone}
                                            onChange={handleInputChange}
                                            pattern="[0-9]{10}"
                                            title="Please enter a valid 10-digit phone number."
                                            required
                                        />
                    </div>
                    <div className="col-lg-12">
                    <p>Tell us about your project</p>
                    <textarea className="msgField"  aria-describedby=""
                                            placeholder=""
                                            name="comment"
                                            value={inputValue?.comment}
                                            required
                                            onChange={handleInputChange}></textarea>
                    </div>
                    </div>
                    
                    <div className="bGray">
                    {!loader &&<button className="team-btns">Submit</button>}
                    {loader && <img src="/assets/images/Loader.gif" alt="loader" className="w-100"/>}
                    </div>
                    </div>
                    </form>
                    }

                    {thankForm &&  <div className="form2 form3">
                    <div className="row">
                    <div className="col-lg-12">
                    <h6>Let's work together!</h6>
                    <img src="/assets/images/Group 156.png" alt="" className=""/>
                    <h6>Success!</h6>
                    <p>We're directing you to a team member's calendar to book a meeting.</p>
                    </div>
                    
                    </div>
                    
                    <div className="bGreen">
                    
                    </div>
                    </div>}
                </div>
            {/* <div className="banner-from">
                <div className="card">
                    {props?.heading ?
                        <div className="card-header">
                            <h5>{props?.heading}</h5>
                        </div>
                        : null}
                    <form onSubmit={e => handleSubmit(e)}>
                        <div className="card-body">
                            {props?.subheading ?
                                <h4 >{props?.subheading}</h4>
                                : null}

                            <div className="row">
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Name"
                                            name="name"
                                            value={inputValue?.name}
                                            onChange={handleInputChange}
                                            pattern="[^0-9]*"
                                            title="Please enter a valid text without numbers."
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Last Name"
                                            name="lastName"
                                            value={inputValue?.lastName}
                                            onChange={handleInputChange}
                                            pattern="[^0-9]*"
                                            title="Please enter a valid text without numbers."
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Company Name"
                                            name="companyName"
                                            value={inputValue?.companyName}
                                            onChange={handleInputChange}
                                            pattern="[^0-9]*"
                                            title="Please enter a valid text without numbers."
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div class="form-group">
                                        <input
                                            type="email"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Business Email"
                                            name="businessEmail"
                                            value={inputValue?.businessEmail}
                                            onChange={handleInputChange}
                                            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}"
                                            title="Please enter a valid email address."
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="Phone number"
                                            name="phoneNumber"
                                            value={inputValue?.phoneNumber}
                                            onChange={handleInputChange}
                                            pattern="[0-9]{10}"
                                            title="Please enter a valid 10-digit phone number."
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <select class=" form-control" aria-label="Default select example"
                                            name="industries"
                                            value={inputValue?.industries}
                                            onChange={handleInputChange} required>

                                            <option selected value="" disabled>Industries</option>
                                            <option value="Healthcare service">Healthcare service</option>
                                            <option value="Professional services">Professional services</option>
                                            <option value="Contact Center">Contact Center</option>
                                            <option value="Financial Service">Financial Service</option>
                                            <option value="Admin and data">Admin and data</option>
                                            <option value="Creative Service">Creative Service</option>
                                            <option value="Tech Support">Tech Support</option>
                                            <option value="Travel Service">Travel Service</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6 ">
                                    <div class="form-group">
                                        <select class=" form-control" aria-label="Default select example"
                                            name="numStaffNeeded"
                                            value={inputValue?.numStaffNeeded}
                                            onChange={handleInputChange} required>

                                            <option selected value="" disabled>Team size</option>
                                            <option value="20+">20 +</option>
                                            <option value="10 - 20">10 - 20</option>
                                            <option value="10 - 5">10 - 5</option>
                                            <option value="3 - 4">3 - 4</option>
                                            <option value="1 - 2">1 - 2</option>
                                            <option value="Not Sure">Not Sure</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div class="form-group">
                                        <select class=" form-control" aria-label="Default select example"
                                            name="serviceType"
                                            value={inputValue?.serviceType}
                                            onChange={handleInputChange} required>

                                            <option selected value="" disabled>Service Type</option>
                                            <option value="Inboaund">Inbound</option>
                                            <option value="Outbound">Outbound</option>
                                            <option value="BPO">BPO</option>
                                            <option value="General Inquiry">General Inquiry</option>
                                            <option value="Partnership">Partnership</option>
                                            <option value="Others">Others</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div class="form-group">
                                        <select class=" form-control" aria-label="Default select example"
                                            name="teamSetupTime"
                                            value={inputValue?.teamSetupTime}
                                            onChange={handleInputChange} required>

                                            <option selected value="" disabled>How soon would like to set up the team?</option>
                                            <option value="Immediately">Immediately</option>
                                            <option value="In the upcoming 30 days">In the upcoming 30 days</option>
                                            <option value="Immediately">Immediately</option>
                                            <option value="In the upcoming 90 days">In the upcoming 90 days</option>
                                            <option value="Still gathering information">Still gathering information.</option>
                                        </select>
                                    </div> */}
                                    {/* <div class="form-group">
                                        <input
                                            type="text"
                                            class="form-control"
                                            id=""
                                            aria-describedby=""
                                            placeholder="How soon would like to set up the team?"
                                            name="teamSetupTime"
                                            value={inputValue?.teamSetupTime}
                                            onChange={handleInputChange}
                                            required
                                        />
                                    </div> */}
                                {/* </div>
                            </div>
                            <div className="text-center">
                                {!loader && <button className={`team-btn ${loader ? "disable" : null}`}>Submit</button>}
                                {loader && <img src="/assets/images/Loader.gif" alt="loader" className="w-100"/>}
                            </div>
                        </div>
                    </form>
                </div>
            </div> */}
        </>
    );
};
export default HomeForm;
