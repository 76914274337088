import React, { useState, useEffect } from "react";
import "./index.css"
import {Helmet} from "react-helmet";

const CaseStudy = () => {
    const [blogdata, setblogdata, data] = useState({});

    useEffect(async () => {
        try {
            const res = await fetch("https://www.sellmymiles.com/staffwiz/wp-json/wp/v2/posts?categories=3&_embed&per_page=100", {
                method: "GET",
                statusCode: 200,
                headers: {
                    'Content-Type':'application/json',
                },
                
            })

            let data = await res.json(); console.log(data);
            if (data && data.length)
                setblogdata(data); console.log(data);
        }
        catch (e) {
            console.log(e)
        }
    }, []);

    const decodeHtmlCharCodes = (str) => {
        return str.replace(/(&#(\d+);)/g, function (match, capture, charCode) {
            return String.fromCharCode(charCode);
        });
    }

    return (
        <>
         <Helmet>
            <title>Case Study | StaffWiz</title>
            <meta name="description" content="Case Study Page" />
        </Helmet>
            <section className="blog">
                <h1>Case Study</h1>

                <div className="container">
                    <div className="row">
                   
                        {Array.isArray(blogdata) && blogdata?.map((data) => {
                            return (
                                <div className="col-12 col-md-6 col-lg-4">
                                    <a href={data.slug}>
                                        <div className="card">
                                            <img className="sm-img" src={data._embedded['wp:featuredmedia']['0'].source_url}></img>
                                            <div className="card-info">
                                                <h2>{decodeHtmlCharCodes(data.title.rendered)}</h2>
                                                <h6>{data.date.split("T")[0]}</h6>
                                            </div>
                                        </div>
                                    </a>
                                </div>

                            )
                        })}


                    </div>
                </div>
            </section>
        </>
    )

}

export default CaseStudy;