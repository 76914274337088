import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/readyToScale"
import ClientsWords from "../../../component/ClientWord";
import StrategicPartners from "../../../component/StrategicPartners";
import {Helmet} from "react-helmet";

const DigitalMarketing = () => {
    return (
        <>
        <Helmet>
            <title>Outsource Marketing | Outsource Digital Marketing | Outsource Marketing Team | Outsource Digital Marketing Services - Staffwiz</title>
            <meta name="description" content="Outsource marketing with Staffwiz's expert team. Get comprehensive outsource digital marketing services and elevate your brand with our dedicated outsource marketing team." />
        </Helmet>
            <section className="home-banner lndg-banner">
                <div className="container">
                    <h1>Hire your world class Digital Marketing Team</h1>
                    <h3 className="my-4">Solutions for startups & small-medium businesses</h3>
                    <div className="row align-items-end">

                        <div className="col-lg-4 right-info">


                            <div className="row px-lg-3 main-catagiory">
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Save 70% on staffing costs</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Hire in weeks not months</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Boost productivity</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Quality & revenue guaranteed </b></h5>
                                </div>
                            </div>
                            <div className="home-rating-banner mt-4">
                                <img src="assets/images/SW Website asset13.png" alt="review stars"></img>
                                <img src="assets/images/HIPAA-COMPLIANT 1.png" alt="review stars"></img>
                                <img src="assets/images/SW Website asset15.png" alt="review stars"></img>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img
                                src="assets/images/home-banner1.png"
                                className="img-fluid" alt="instruction"
                            ></img>
                        </div>
                        <div className="col-lg-4">
                            <GetLandingForm heading={"HIRE NOW"} />
                        </div>
                    </div>
                </div>
            </section>
            {/* details and services */}
            <section className="who-are">
                <div className="container">
                    <h2 className="heading text-center w-100 mb-0">Hire Digital Marketing Team starting from $7/hr*</h2>
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="lndng-auto">
                                <p className="text-center mt-0 mb-1">Need a Digital Marketing expert … Or Two? Or Maybe a Whole Team?</p>
                                <p className="text-center mt-0">Let us show you how outsourcing digital marketing services can adeptly fulfill your employment needs, all while significantly reducing costs.</p>
                                <p className="text-center mt-0">Delegate your marketing tasks to an expert and prioritize crucial aspects of your business. Let a digital marketing assistant handle social media, email campaigns, and SEO, saving you time and effort.</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-8 landing-hire-bar">
                            <div className="bar1">No contracts</div>
                            <div className="bar2">No obligations</div>
                            <div className="bar3">No hidden fees</div>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Managing social media accounts</h4>
                            <p>
                                Social media marketing is key for business growth. A virtual assistant can manage your social media presence, reaching your target audience on platforms like Facebook, Instagram, and Twitter.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>SEO optimization</h4>
                            <p>
                                To succeed online, you need a high search engine ranking. SEO, handled by marketing virtual assistants, can optimize your website for better rankings on Google and other search engines.
                            </p>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Content creation and management</h4>
                            <p>
                                Content marketing is essential for business success. A marketing virtual assistant creates engaging content for your website, social media, and marketing needs, helping enhance your marketing efforts.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Email marketing campaigns</h4>
                            <p>
                                Email marketing is not outdated. It remains beneficial for businesses today with a database of email addresses. Use professional tools to create and send targeted email campaigns.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
<p className="container" >Discover the power of strategic outsourcing with our outsource marketing services. Fill up the form, and let us craft transformative solutions tailored to your marketing goals.</p>
            <BenifitsOfOutsourcing title={"Digital Marketing"} />

            <HiringIsEasy />
<p className="container" >Experience the prowess of an outsourced marketing team and the finesse of our digital marketing services. Let us be your solution architect, driving your business forward.</p>
            <ClientsWords />
            <StrategicPartners />

            {/* <HireTalent /> */}
            <ReadyToScale />
        </>
    );
};
export default DigitalMarketing;
