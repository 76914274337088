import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/readyToScale"
import ClientsWords from "../../../component/ClientWord";
import StrategicPartners from "../../../component/StrategicPartners";
import {Helmet} from "react-helmet";

const CustomerService = () => {
    return (
        <>
        <Helmet>
            <title>Business Process Outsourcing | Outsource Customer Service | Outsourced Call Center In USA - StaffWiz</title>
            <meta name="description" content="Looking to outsource customer service?  enhance your customer interactions with Performance-driven inbound & outbound call center service in just a call away." />
        </Helmet>
            <section className="home-banner lndg-banner">
                <div className="container">
                    <h1>Hire your world class Customer Service Team </h1>
                    <h3 className="my-4">Solutions for startups & small-medium businesses</h3>
                    <div className="row align-items-end">

                        <div className="col-lg-4 right-info">


                            <div className="row px-lg-3 main-catagiory">
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Save 70% on staffing costs</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Hire in weeks not months</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Boost productivity</b></h5>
                                </div>
                                <div className="col-lg-12 col-12">
                                    <h5 className=""><b>Quality & revenue guaranteed </b></h5>
                                </div>
                            </div>
                            <div className="home-rating-banner mt-4">
                                <img src="assets/images/SW Website asset13.png" alt="review stars"></img>
                                <img src="assets/images/HIPAA-COMPLIANT 1.png" alt="review stars"></img>
                                <img src="assets/images/SW Website asset15.png" alt="review stars"></img>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <img
                                src="assets/images/home-banner1.png"
                                className="img-fluid" alt="instruction"
                            ></img>
                        </div>
                        <div className="col-lg-4">
                            <GetLandingForm heading={"HIRE NOW"} />
                        </div>
                    </div>
                </div>
            </section>
            {/* details and services */}
            <section className="who-are">
                <div className="container">
                    <h2 className="heading text-center w-100">Hire Customer Service experts starting from $6/hr*</h2>
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <p className="lndng-auto">Need a Customer Service expert … Or Two? Or Maybe a Whole Team?</p>
                            <p className="lndng-auto">Let us show you how business process outsourcing can fill your employment needs at a fraction of the cost</p>
                            <p className="lndng-auto">Outsourcing customer service with Staffwiz provides cost savings, operational efficiency, and scalability. By partnering with Staffwiz, businesses can reduce overhead costs, leverage their expertise and advanced technology, and easily scale their customer support operations to meet changing demands.</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-8 landing-hire-bar">
                            <div className="bar1">No contracts</div>
                            <div className="bar2">No obligations</div>
                            <div className="bar3">No hidden fees</div>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Handling inquiries and support</h4>
                            <p>
                                Customer service representatives interact with customers via various channels such as phone, email, chat, or social media. They address inquiries, troubleshoot problems, provide product or service information, and assist with order placement or returns.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Order processing</h4>
                            <p>
                                Outsourced customer service teams often handle order processing tasks such as taking customer orders, verifying details, processing payments, and ensuring timely delivery.
                            </p>
                        </div>
                        <div className="col-lg-2 "></div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Technical support</h4>
                            <p>
                                Outsourced customer service providers offer technical support for products or services with technical aspects. They assist with software or hardware issues, troubleshoot technical problems, and provide product usage guidance.
                            </p>
                        </div>
                        <div className="col-lg-4 landing-hire-block">
                            <span></span>
                            <h4>Multilingual support</h4>
                            <p>
                                Outsourced customer service providers offer multilingual support for diverse customers worldwide, with representatives fluent in various languages.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
<p className="container"> Streamline operations through business process outsourcing, while delivering exceptional customer support via our outsourced call center services, redefining outsource customer service</p>
            <BenifitsOfOutsourcing title={"Customer Service"} />

            <HiringIsEasy />
<p className="container">Elevate customer connections through outbound call center outsourcing. Amplify satisfaction with expert customer support outsourcing. Enhance engagement via seamless live chat outsourcing. Trust us for growth - take action with a free consultation.</p>
            <ClientsWords />
            <StrategicPartners />

            {/* <HireTalent /> */}
            <ReadyToScale />


        </>

    );
};
export default CustomerService;
