import React from "react";
import "../index.css";
import GetLandingForm from "../../../component/getLandingForm"
import BenifitsOfOutsourcing from "../../../component/benifitsOfOutsourcing"
import HiringIsEasy from "../../../component/hiringIsEasy"
import ReadyToScale from "../../../component/serviceReadyToScale"
import ClientsWords from "../../../component/ClientWord";
import ServiceWhyChoose from "../../../component/serviceWhyChoose";
import ServiceBenefits from "../../../component/serviceBenefits";
import {Helmet} from "react-helmet";

const Telemarketing = () => {
    return (
        <>
        <Helmet>
            <title>Telemarketing Services | Tele Marketing Company | Telemarketing Outsourcing - Staffwiz</title>
            <meta name="description" content="Boost your sales with expert telemarketing services from Staffwiz. Our tele marketing company specializes in telemarketing outsourcing, B2B telemarketing, and cold calling services." />
        </Helmet>
           <section className="home-banner  careers-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6">
                                    <h1>
                                        Telemarketing
                                    </h1>
                                    <p>StaffWiz elevates telemarketing success with efficient scheduling, real-time monitoring, and analytics. Specializing in outsourcing, we ensure compliance and scalability. Partner with StaffWiz for superior B2B telemarketing, empowering your team for exceptional results.
</p>
                                    
                                </div>
                                <div className="col-lg-6 right-info">
                                    <img
                                        className="img-fluid"
                                        src="assets/images/12 1.png"
                                    ></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    <section className="service-form">
    <img
                                        className="img-fluid dsgn-img1"
                                        src="assets/images/BPO Service Materials 1.png"
                                    ></img>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 mx-auto">
                            <div className="row  align-items-center">

                                <div className="col-lg-6 pd30">
                                    <GetLandingForm heading={"Elevate Your Business with Telemarketing by Staffwiz"} />
                                </div>
                        
                                <div className="col-lg-6 right-inf pd30">
                                <ServiceWhyChoose title={"Telemarketing"} />
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
         <img
                                        className="img-fluid dsgn-img2"
                                        src="assets/images/2 565.png"
                                    ></img>
            </section>
     <ServiceBenefits title={"telemarketing"} />
     <div className="container realizeP">
                    <div className="row">
                    <div className="col-lg-9 mx-auto">
     <p>Transform your telemarketing strategies with StaffWiz, your trusted tele marketing company. Our telemarketing services, including telemarketing outsourcing and telesales outsourcing, are tailored for success. Contact StaffWiz today for expert solutions in B2B telemarketing and cold calling services, and take your campaigns to new heights of efficiency and effectiveness.</p>
           </div>
   <div className="col-lg-3 mx-auto">
   </div>
           </div>
            </div>
             <ClientsWords />
           

            {/* <HireTalent /> */}
            <ReadyToScale />


        </>

    );
};
export default Telemarketing;
