import React from "react";
import {Helmet} from "react-helmet";

function About() {
    return (
        <>
        <Helmet>
            <title>About Us | StaffWiz</title>
            <meta name="description" content="Learn more about StaffWiz, a leading staffing and recruitment platform. Discover our mission, vision, and values as we strive to connect talented professionals with top organizations. Find out how we can assist you in finding the right job or the right candidate." />
        </Helmet>
            <div className="container mb-5">
                <h1 className="text-center mb-4">About Us</h1>
                <p>
                    At Staffwiz, Our team of experts work in a consultative and collaborative partnership with organizations globally offering customized BPO-Staffing solutions to meet each client’s specific needs. Our experts work as strategic partners with organizations globally to design customized service delivery Models that increase labor and processing efficiency, increase employee engagement, stabilize work results and alleviate risks. StaffWiz is ready with a team of experts and solutions allowing you to focus on growing your company business goals.
                </p>

                <p>
                    Our mission is to help you grow your business with reliable, flexible offshoring solutions in the Philippines.
                </p>
                <p>
                    We value integrity, clarity, professionalism, social responsibility, and family. Our workers carry these values with them in every job they do.
                </p>
                <p>
                    The "work from home" model has proven very successful lately, and as a result, outsourcing has been introduced to many companies as a viable option. Outsourcing has been in practice for years, yet many companies glossed over it until recently. Companies have found out that outsourcing can cut costs and add flexibility to their workers.
                </p>
                <p>
                    If you want quality recruitment as we do then join our offshore team in the Philippines. Our Offshore workers are the heartbeat of our business and will always be motivated and passionate about our process to hire overseas employees. Our unique way of hiring remote employees in the Philippines has been our constant process to our staffing success.
                </p>
                <p className="py-0">Hire An Offshore Staffing Company Today!</p>
            </div>
        </>
    )
}
export default About;