import React from "react";
import { PopupButton } from "react-calendly";
import { APP_CONFIG } from "../config/config";
//import GetStartForm from "./getStartForm"

const ServiceReadyToScale = () => {
    return (
        <section className="scale-business">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-8">
                            <h1 className="heading">Ready to scale your business?</h1>
                            <h3>Setting up is easy</h3>
                            <PopupButton
                                url={APP_CONFIG.CALENDELY.url}
                                rootElement={document.getElementById("root")}
                                text="Free Consultation"
                                className="team-btn mt-4 mb-5"
                            />
                        </div>
                        <div className="col-lg-4">
                            <img className="img-fluid " src="assets/images/scale.png"></img>
                        </div>
                    </div>
                </div>
            </section>
    )

}
export default ServiceReadyToScale;