import React from "react";
import "./index.css";
import "../Home/index.css";
import ClientsWords from "../Home/ClientWord";
import StrategicPartners from "../Home/StrategicPartners";
import { PopupButton } from "react-calendly";
import { APP_CONFIG } from "../../config/config";

const Getstarted_Thankyou = () => {

    return (
        <>
        <div className="started-thankyou-container">
            <div className="container">
                <div className="row">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-10">
                        <div className="team-popup-cotent">

                            <div className="bill-heading">
                                <div className="position-relative margin-t20px text-center">
                                    <h1 className="pt-5 font-weight-bold">
                                        Thank you
                                    </h1>
                                    <h3 className="font-weight-bold">Your request has been received</h3>
                                    <p style={{maxWidth:'602px', fontSize:'18px'}} className="mx-auto mt-3">While our sales team is getting ready to respond to your request, feel free to book a call with our sales experts to get a service presentation. </p>
                                </div>
                            </div>
                                <div className="text-center mb-5">
                                   <a href="/schedule-call" className="team-btn mt-4 mb-5">FREE CONSULTATION</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



        </div>

            <ClientsWords className="mt-5" />
            <StrategicPartners className="my-5" />
        </>
    )
}

export default Getstarted_Thankyou;