import React from "react";
import "./index.css";
import GetStartForm from "../../component/getStartForm"
import HireTalent from "../../component/hireTalent"
import { PopupButton } from "react-calendly";
import { APP_CONFIG } from "../../config/config";
import {Helmet} from "react-helmet";

const Whyus = () => {
    return (
        <>
        <Helmet>
            <title>Top RPO  Recruiting Companies In USA- Staffwiz</title>
            <meta name="description" content="We are fast paced, high growth Start-up rpo recruiting company." />
        </Helmet>
            <section className="home-banner innerHome-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7 right-info">
                            <h1>Acquire the most valuable talent for your business. </h1>

                            <img className="img-fluid" src="assets/images/inner-home.png"></img>
                        </div>

                        <div className="col-lg-5">
                            <GetStartForm />

                            <div className="row px-lg-3 main-catagiory">
                                <div className="col-lg-6 col-6">
                                    <h5 className=""><b>Sales</b></h5>
                                </div>
                                <div className="col-lg-6 col-6">
                                    <h5 className=""><b>Human Resources</b></h5>
                                </div>
                                <div className="col-lg-6 col-6">
                                    <h5 className=""><b>Support</b></h5>
                                </div>
                                <div className="col-lg-6 col-6">
                                    <h5 className=""><b>Marketing</b></h5>
                                </div>
                                <div className="col-lg-6 col-6">
                                    <h5 className=""><b>Accounting</b></h5>
                                </div>
                                <div className="col-lg-6 col-6">
                                    <h5 className=""><b>Operation</b></h5>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="why-use2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <div className="row">
                                <div className="col-lg-7">
                                    <h2 className="heading">Why Us</h2>
                                    <h4><b>Your Trusted Staffing Partner</b></h4>
                                    <p>
                                        Welcome to  Staffwiz  your premier staffing solution provider. We understand that finding the right talent is crucial for the success of your organization. With our exceptional expertise and comprehensive approach, we are dedicated to being your trusted staffing partner. Here's why you should choose us:

                                    </p>
                                    <div className="row mt-2 text-center">
                                        <a href="https://www.youtube.com/watch?v=xVEhOzvrnbI" className="btn-brnd-outline mr-4 my-4">
                                            WATCH HERE <i class="fa-solid fa-caret-right"></i>
                                        </a>
                                        {/* <a className="team-btn my-4">FREE CONSULTATION</a> */}
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <img className="img-fluid w-100" src="assets/images/why.png"></img>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="our-services expertise">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <h1 className="heading heading-border">Experience and Expertise</h1>
                            <div className="row mt-5 pt-lg-5">
                                <div className="col-lg-4 col-container mb-4">
                                    <h5 className="built-point">Extensive Industry Knowledge:</h5>
                                    <p>Our team stays informed about industry trends, technologies, and skill requirements. This enables us to understand your staffing needs and provide tailored solutions.</p>
                                </div>
                                <div className="col-lg-4 col-container mb-4">
                                    <h5 className="built-point"> Streamlined Recruitment Process:</h5>
                                    <p>We streamline recruitment, saving you time and resources. Our experts handle everything from sourcing to interviews and background checks, while you focus on your core business.</p>
                                </div>
                                <div className="col-lg-4 col-container mb-4">
                                    <h5 className="built-point"> Proven Track Record:</h5>
                                    <p>We excel at matching professionals with organizations, delivering quality candidates and exceeding expectations as a reliable staffing partner.</p>
                                </div>
                                <div className="col-lg-4 col-container mb-4">
                                    <h5 className="built-point"> Vast Talent Network:</h5>
                                    <p>Our network comprises skilled professionals with diverse expertise and experience. We carefully screen and select qualified candidates, presenting you with the best-suited individuals for your positions.</p>
                                </div>
                                <div className="col-lg-4 col-container mb-4">
                                    <h5 className="built-point"> Flexibility and Scalability:</h5>
                                    <p>We provide adaptable staffing solutions for all your business needs, from temporary to permanent positions, part-time or remote. Our scalable services cater to any staffing requirement, big or small.</p>
                                </div>
                                <div className="col-lg-4 col-container mb-4">
                                    <h5 className="built-point"> We are proactive:</h5>
                                    <p>In today's market, passive employment approaches are no longer affordable. Instead, great talent and opportunities require proactive recruiters for their discovery.</p>
                                </div>
                                <div className="col-lg-4 col-container mb-4">
                                    <h5 className="built-point"> Customized Approach:</h5>
                                    <p>We value personalized solutions. Our team understands your unique needs, culture, and goals. This helps us find candidates who not only have the required skills but also integrate well into your team, ensuring long-term success.</p>
                                </div>
                                <div className="col-lg-4 col-container mb-4">
                                    <h5 className="built-point"> Exceptional Client Support:</h5>
                                    <p>We prioritize strong client relationships, offering dedicated support, prompt query response, and ongoing assistance. Your satisfaction is our priority.</p>
                                </div>
                                <div className="col-lg-4 col-container mb-4">
                                    <h5 className="built-point"> Certified Assurance Excellence</h5>
                                    <p>We are ISO27001 certified and ISO 9001 certified and HIPPA compliant so you can rest assured you dealing with a company that maintains the highest industry standards of service and delivery</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="available-clock">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <h2 className="heading heading-border">
                                Continuous backup and redundancy available round the clock.
                            </h2>
                            <div className="row align-items-center">
                                <div className="col-lg-6">
                                    <img src="assets/images/backup.png" className="img-fluid w-100"></img>
                                </div>
                                <div className="col-lg-6">
                                 <h5 className="built-point">Ensure data security with continuous backup and redundancy, available 24/7. Our service is trusted by top RPO companies to keep your critical information safe and accessible at all times.</h5>
                                    <h5 className="built-point">We provide additional backup resources at no extra cost.</h5>
                                    <h5 className="built-point">To accommodate your business requirements, our virtual assistants are available in different time zones, ranging from EST to PST.</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="employee-performance">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-10 mx-auto">
                            <h1 className="heading heading-border">Evaluation of <br></br> employee performance</h1>
                            <div className="row">
                            <p>Enhance workforce efficiency through meticulous evaluation of employee performance. Our proven approach is embraced by top RPO companies to ensure optimal team productivity and growth.</p>
                                <div className="col-lg-4">
                                    <div className="employee-box">
                                        <img src="assets/images/employ (3).png"></img>
                                        <p>The team leads diligently oversee the staff's progress to make certain that they achieve their targets.</p>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="employee-box">
                                        <img src="assets/images/employ (2).png"></img>
                                        <p>Mandatory initial training lasting 2-4 weeks is a prerequisite for onboarding our virtual assistants.</p>
                                        <p></p>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="employee-box">
                                        <img src="assets/images/employ (1).png"></img>
                                        <p>Send weekly quality reports and bi-weekly feedback emails regarding staff productivity and management concerns.</p>
                                        <p></p>
                                    </div>
                                </div>
                            </div>
                            <div className="">
                               <a href="/schedule-call" className="team-btn mt-4 mb-5">FREE CONSULTATION</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="maintaining-confidentiality">
                <div className="row">
                    <div className="col-lg-10 mx-auto">
                        <div className="container">
                            <h1 className="heading ">
                                Safeguarding of data and maintaining its confidentiality.
                            </h1>
                            <div className="row">
                                <div className="col-lg-10 mx-auto">
                                    <div className="row text-center">
                                        <div className="col-lg-6 mt-4">
                                            <img className="img-fluid icon-img" src="assets/images/maintaining (1).png"></img>
                                            <p>Our compliance with the ISO 27001 data security standard ensures that Staffwiz adheres to rigorous protocols for cyber security and employs best practices in data and communication management, providing you with a trustworthy and reliable service.</p>
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <img className="img-fluid icon-img" src="assets/images/maintaining (4).png"></img>
                                            <p>Virtual assistants are required to sign non-disclosure agreements which provide safeguard against infringement of intellectual property rights (IPR) and misuse of client data.</p>
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <img className="img-fluid icon-img" src="assets/images/maintaining (3).png"></img>
                                            <p>At Staffwiz, we make sure that all of the work created by our employees meets the requirements of international licensing standards.</p>
                                        </div>
                                        <div className="col-lg-6 mt-4">
                                            <img className="img-fluid icon-img" src="assets/images/maintaining (2).png"></img>
                                            <p>In order to comply with employment regulations specific to each region, we have hired labor attorneys in all countries where we operate.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="scale-business">
                <div className="container">
                    <div className="row align-items-end">
                        <div className="col-lg-8">
                            <h1 className="heading">Ready to scale your business?</h1>
                            <h4>Setting up is easy</h4>
                            <h5>Call now</h5>
                           <a href="/schedule-call" className="team-btn mt-4 mb-5">FREE CONSULTATION</a>
                        </div>
                        <div className="col-lg-4">
                            <img className="img-fluid " src="assets/images/scale.png"></img>
                        </div>
                    </div>
                </div>
            </section>

        </>
    );
};
export default Whyus;
